import React from "react";

import get from "lodash/get";

const definition = {
  form_label: "Registration",
  due_at_label: "Registration is due <b>April 29, 2023</b>",
  title: "Skyridge Youth Cheer Registration 2023",
  allow_multiple_per_user: true,
  login_description:
    "<p>In order to start or continue registration for Skyridge Youth Cheer, please log in with your Google account below.</p>",
  start_introduction:
    "<p>Thanks for your interest in the 2023-2024 Skyridge Youth Cheer Program!</p><p>Please enter the name of the youth cheerleader below and click the Start button to continue.</p>",
  applications_list_description:
    "<p>Select a registration below to continue, or click the Start button to start a new one.</p>",
  start_user_field_label: "Youth Name",
  start_button_label: "Start NEW Registration",
  hack_hide_logo: true,
  auto_guardian_approval: true,
  email_from: "Skyridge Cheerleading <notifications@mg.letsapply.io>",
  email_replyto: "skyridgefalconscheer@gmail.com",
  email_bcc: ["skyridgefalconscheer@gmail.com"],
  email_help: "skyridgefalconscheer@gmail.com",
  tryout_number_prefix: "HUNDREDS_BY_GRADE",
  submit_ready_text:
    '<p>You can now submit your registration. Click "Review Registration" below to start.</p>',
  review_button_text: "Review Registration",
  submit_review_text:
    '<p><b>You\'re not done yet!</b> Once you have submitted your registration, you cannot make further changes without emailing us and asking to be unsubmitted. Please verify that your answers below are correct and complete, and then click "Submit Registration".</p>',
  submit_button_text: "Submit Registration",
  submitted_text:
    "Registration submitted <b>{{formatDateTime userData.submitted}}</b>",
  application_approved_text:
    'Your tryout number is <span class="user-number">{{userData.number}}</span>',
  application_submitted:
    "Your registration has been submitted. Please contact us if you need to make changes.",
  view_application_button_text: "View Registration",
  application_approved_email: {
    subject:
      "Skyridge Youth Cheer Registration Submitted for {{userData.name}}",
    html: '<p>Your registration for the 2023-2024 Skyridge Youth Cheer Program has been submitted.</p><p>You do not need to submit any additional paperwork. You can download a copy of your signed forms for your own records here:</p><p><a href="{{pdfUrl}}">Signed registration forms.</a></p><p>- Skyridge Youth Cheer Coaches</p>',
  },
  overview: {
    sections: [
      {
        key: "basic",
        label: "",
        columns: [
          {
            key: "user.number_and_progress",
            label: "#",
          },
          {
            key: "user.application.name",
            label: "Name",
            link: true,
            hover: (data) => {
              if (get(data, "user.application.headshot[0].url")) {
                return (
                  <img
                    alt="headshot"
                    style={{ width: "auto", height: "400px" }}
                    src={get(data, "user.application.headshot[0].url")}
                  />
                );
              } else {
                return "No headshot";
              }
            },
          },
          {
            key: "user.application.grade",
            label: "Year",
          },
          {
            key: "user.assessments.team",
            label: "Team",
          },
          {
            key: "user.application.guardian_name",
            label: "Parent",
          },
          {
            key: "user.application.email",
            label: "Parent Email",
          },
          {
            key: "user.application.guardian_phone",
            label: "Parent Phone",
          },
        ],
      },
      {
        key: "standing_tumbling",
        label: "Standing Tumbling",
        hover: "user.application.st_comments",
        columns: [
          {
            key: "user.application.st_bridge",
            label: "Bridge",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.st_bhs",
            label: "BHS",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.st_tuck",
            label: "Tuck",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.st_full",
            label: "Full",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
        ],
      },
      {
        key: "running_tumbling",
        label: "Running Tumbling",
        hover: "user.application.rt_comments",
        columns: [
          {
            key: "user.application.rt_cartwheel",
            label: "CW",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.rt_ro_bhs",
            label: "RO BHS",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.rt_ro_multiple_bhs",
            label: "RO BHS BHS",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },

          {
            key: "user.application.rt_cartwheel_bt",
            label: "CW BT",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.rt_ro_bhs_bt",
            label: "RO BHS BT",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.rt_ro_bhs_l",
            label: "RO BHS L",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.rt_ro_bhs_l_so",
            label: "RO BHS L SO",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.rt_ro_bhs_full",
            label: "RO BHS F",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
        ],
      },
    ],
  },
  sections: [
    {
      title: "Basic Information",
      icon: "Person",
      print: false,
      fields: [
        {
          label: "Participant Name",
          key: "name",
          type: "textfield",
          required: true,
          validate: "fullName",
          assessments: [
            {
              key: "team",
              label: "Team",
              type: "select",
              values: ["", "-", "1st-3rd", "4th/5th", "6th", "7th", "8th/9th"],
            },
          ],
        },
        {
          label: "Gender",
          key: "gender",
          type: "select",
          values: ["Male", "Female"],
          required: true,
        },
        {
          label: "Date of Birth",
          key: "birthday",
          type: "date",
          required: true,
          initialFocusedDate: "1/1/2004",
        },
        {
          label: "School Grade for CURRENT year.",
          key: "grade_current",
          type: "select",
          values: ["K", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th"],
          required: true,
        },
        {
          label: "School Grade for 2023-2024 School Year",
          key: "grade",
          type: "select",
          values: [
            "1st",
            "2nd",
            "3rd",
            "4th",
            "5th",
            "6th",
            "7th",
            "8th",
            "9th",
          ],
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div>
              <br />
              <span style={{ color: "red" }}>
                <b>WARNING!</b>
              </span>{" "}
              Please check that you entered the correct grades above.
            </div>
          ),
          hidden: (userData) => {
            let { grade_current, grade } = userData;

            if (!grade_current || !grade) return true;
            if (grade_current === "K" && grade === "1st") return true;
            if (grade_current === "1st" && grade === "2nd") return true;
            if (grade_current === "2nd" && grade === "3rd") return true;
            if (grade_current === "3rd" && grade === "4th") return true;
            if (grade_current === "4th" && grade === "5th") return true;
            if (grade_current === "5th" && grade === "6th") return true;
            if (grade_current === "6th" && grade === "7th") return true;
            if (grade_current === "7th" && grade === "8th") return true;
            if (grade_current === "8th" && grade === "9th") return true;

            return false;
          },
        },
        {
          label: "Parent/Legal Guardian Name",
          key: "guardian_name",
          type: "textfield",
          required: true,
          validate: "fullName",
        },
        {
          label: "Parent/Legal Guardian Email",
          key: "email",
          type: "textfield",
          subtype: "email",
          validate: "email",
          required: true,
        },
        {
          label: "Parent/Legal Guardian Phone",
          key: "guardian_phone",
          type: "textfield",
          subtype: "tel",
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div>
              <br />
              <br />
              <h3>Emergency Contact</h3>
              <p>Must be someone other than a parent/guardian.</p>
            </div>
          ),
        },
        {
          label: "Emergency Contact Name",
          key: "emergency_contact_name",
          type: "textfield",
          required: true,
          validate: "fullName",
        },
        {
          label: "Relationship",
          key: "emergency_contact_relationship",
          type: "textfield",
          required: true,
        },
        {
          label: "Emergency Contact Phone",
          key: "emergency_contact_phone",
          type: "textfield",
          subtype: "tel",
          required: true,
        },
      ],
    },
    {
      title: "Headshot",
      icon: "Camera",
      print: false,
      wrapperStyle: { margin: "45px 0 0", padding: "0 0 20px 0" },
      fields: [
        {
          type: "upload",
          label: "Headshot",
          key: "headshot",
          required: true,
          accept: "image/*",
          showImage: true,
        },
      ],
    },
    {
      title: "Experience",
      icon: "Poll",
      print: false,
      fields: [
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <p>
              Please describe any past cheerleading, tumbling, or dance
              experience you have.
            </p>
          ),
        },
        {
          label: "Experience",
          key: "experience",
          type: "textfield",
          multiline: true,
          rows: 6,
          required: true,
          variant: "outlined",
        },
      ],
    },
    {
      title: "Standing Tumbling Experience",
      icon: "Accessibility",
      print: false,
      fields: [
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <p>
                Please rate yourself on the following standing tumbling skills{" "}
                <b>ON A NON-SPRING FLOOR</b>:
              </p>
              <ul>
                <li>
                  <b>0</b> - No experience
                </li>
                <li>
                  <b>1</b> - Can execute, but not consistently
                </li>
                <li>
                  <b>2</b> - Can consistently hit but not with good form and
                  technique
                </li>
                <li>
                  <b>3</b> - Can consistenly hit with good form and technique
                </li>
              </ul>
            </div>
          ),
        },
        {
          label: "Bridge or back-walkover",
          key: "st_bridge",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Standing back-handspring",
          key: "st_bhs",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Standing back-tuck",
          key: "st_tuck",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Standing full (back-tuck with full twist)",
          key: "st_full",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <hr />
              <p>
                Please expound on your standing tumbling experience. Is there
                anything you would like the coaches to know?
              </p>
            </div>
          ),
        },
        {
          label: "Standing tumbling comments",
          key: "st_comments",
          type: "textfield",
          multiline: true,
          rows: 4,
          required: true,
          variant: "outlined",
        },
      ],
    },
    {
      title: "Running Tumbling Experience",
      icon: "DirectionsWalk",
      print: false,
      fields: [
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <p>
                Please rate yourself on how well you can perform the following
                running tumbling skills <b>ON A NON-SPRING FLOOR</b>:
              </p>
              <p>
                <i>*BHS means back-handspring</i>
              </p>
              <ul>
                <li>
                  <b>0</b> - No experience
                </li>
                <li>
                  <b>1</b> - Can execute, but not consistently
                </li>
                <li>
                  <b>2</b> - Can consistently hit but not with good form and
                  technique
                </li>
                <li>
                  <b>3</b> - Can consistenly hit with good form and technique
                </li>
              </ul>
            </div>
          ),
        },
        {
          label: "Cartwheel or round-off",
          key: "rt_cartwheel",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Round-off BHS",
          key: "rt_ro_bhs",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Round-off multiple BHS",
          key: "rt_ro_multiple_bhs",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Connected cartwheel back-tuck",
          key: "rt_cartwheel_bt",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Round-off BHS back-tuck",
          key: "rt_ro_bhs_bt",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Round-off BHS layout",
          key: "rt_ro_bhs_l",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Round-off BHS layout step-out",
          key: "rt_ro_bhs_l_so",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Round-off BHS full twist",
          key: "rt_ro_bhs_full",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <hr />
              <p>
                Please expound on your running tumbling experience. Is there
                anything you would like the coaches to know?
              </p>
            </div>
          ),
        },
        {
          label: "Running tumbling comments",
          key: "rt_comments",
          type: "textfield",
          multiline: true,
          rows: 4,
          required: true,
          variant: "outlined",
        },
      ],
    },
    {
      title: "Waiver",
      icon: "Gesture",
      print: true,
      paper: true,
      fields: [
        {
          type: "text",
          text: (
            <>
              <span className="center">
                <h1>Skyridge Youth Cheer Waiver</h1>
              </span>
            </>
          ),
        },
        {
          type: "text",
          text: ({ userData, today }) => (
            <div>
              <p>
                This waiver applies to Skyridge Youth Tryouts, Skyridge Youth
                Cheer Program Participation, Skyridge Youth Cheer Camp, and the
                Skyridge Youth Invitational.
              </p>
              <p>
                <b>WARNING:</b> Cheerleading is a vigorous, physical activity
                involving motion, rotation and height in a unique environment
                and as such carries with it a higher than ordinary risk of
                injury. Be advised that serious, catastrophic injury, paralysis
                or even death could occur, particularly if a participant were to
                land on his/her head, neck or back.
              </p>
              <p>
                I give my consent and authorization to any medically trained
                personnel, doctor, or hospital to render treatment or care as
                needed to the said participant, be it according to illness,
                accident, or injury.
              </p>
              <p>
                I understand participation in the Skyridge Youth Tryouts,
                Skyridge Youth Cheer Program, Skyridge Youth Cheer Camp, and the
                Skyridge Youth Invitational is voluntary. I understand the
                dangers and risks of participating in cheerleading activity
                include, but are not limited to, neck and spinal injuries,
                concussion, serious bodily injury, or even death.
              </p>
              <p>
                I understand the potential risks of injury. By signing this
                agreement, I release the employees, coaches, advisors,
                volunteers, students, and staff of Skyridge High School, the
                youth program director, youth coaches, team moms, and the Board
                of Education from any claim of negligence by ourselves and our
                child, and from any liability arising from claims for damages or
                injury to our child and any claims for loss of damage to his/her
                property which may arise out of his/her participation in these
                school-sponsored events.
              </p>
            </div>
          ),
        },
        {
          type: "signature",
          label: "Guardian Signature",
          key: "informed_consent_guardian_sig",
          required: true,
        },
      ],
    },
    {
      title: "Fees",
      icon: "Money",
      print: false,
      fields: [
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <p>
                Registration for Skyridge Youth Cheer tryouts costs $20 per
                registration. Please send it to{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://venmo.com/Jennifer-Frier"
                >
                  @Jennifer-Frier
                </a>{" "}
                on Venmo before submitting your registration.
              </p>
            </div>
          ),
        },
        {
          label: "Check below to acknowledge that you have paid the fee.",
          shortLabel: "Tryout Fee",
          key: "tryout_fee",
          type: "choosemany",
          options: [["paid", "I have paid the $20 tryout fee."]],
          required: true,
        },
        {
          label: (
            <>
              Check below to acknowledge that you have reviewed the Sample Fees
              document at{" "}
              <a
                href="https://skyridgeyouthcheer.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                skyridgeyouthcheer.com
              </a>
              .
            </>
          ),
          shortLabel: "Reviewed Sample Fees",
          key: "reviewed_fees",
          type: "choosemany",
          options: [["reviewed", "I have reviewed the Sample Fees document."]],
          required: true,
        },
        {
          label: (
            <>
              We have been using the same uniform design for the past 4 years,
              and would like to know how much interest there is in getting new
              uniforms this year. Please indicate your preference below, and
              provide any feedback you may have.
            </>
          ),
          shortLabel: "Uniform Preference",
          key: "uniform_preference",
          type: "chooseone",
          options: [
            ["keep", "I would prefer to KEEP the current uniforms."],
            ["new", "I would prefer to get NEW uniforms."],
          ],
          required: true,
        },
        {
          label: "Comments about potentially getting new uniforms.",
          key: "uniform_comments",
          type: "textfield",
          multiline: true,
          rows: 4,
          required: false,
          variant: "outlined",
        },
      ],
    },
    {
      title: "Tryout Video",
      icon: "EmojiPeople",
      print: false,
      fields: [
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <p>
                Please upload your uncut tryout video here. Detailed tryout
                video instructions are available at{" "}
                <a
                  target="_blank"
                  href="https://skyridgeyouthcheer.com"
                  rel="noopener noreferrer"
                >
                  skyridgeyouthcheer.com
                </a>
                .
              </p>
            </div>
          ),
        },
        {
          type: "upload",
          label: "Tryout Video",
          key: "tryout_video",
          required: (userInfo) => userInfo.gender !== "Male",
          maxFiles: 1,
          accept: "video/*, .3gp, .mp4, .m4a",
          showImage: false,
        },
      ],
    },
  ],
};

export default definition;
