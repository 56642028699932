import React from "react";

const SHIRT_SIZES = [
  { value: "XS (Child)", label: "Child XS (5T)" },
  { value: "S (Child)", label: "Child S (6-7)" },
  { value: "M (Child)", label: "Child M (8-10)" },
  { value: "L (Child)", label: "Child L (12-14)" },
  { value: "XL (Child)", label: "Child XL" },
  { value: "XS (Adult)", label: "Adult XS" },
  { value: "S (Adult)", label: "Adult S" },
  { value: "M (Adult)", label: "Adult M" },
  { value: "L (Adult)", label: "Adult L" },
];

const definition = {
  form_label: "Registration",
  due_at_label: "Registration is due <b>June 15, 2023</b> at noon",
  title: "Skyridge Mini Cheer & Stunt Crew Camp Registration 2023",
  allow_multiple_per_user: true,
  login_description:
    "<p>In order to start or continue registration for the Skyridge Mini Cheer &amp; Stunt Crew Camp, please log in with your Google account below.</p>",
  start_introduction:
    "<p>Thanks for your interest in the 2023 Skyridge Mini Cheer &amp; Stunt Crew Camp!</p><p>Please enter the name of the youth cheerleader below and click the Start button to continue.</p>",
  applications_list_description:
    "<p>Select a registration below to continue, or click the Start button to start a new one.</p>",
  start_user_field_label: "Child Name",
  start_button_label: "Start NEW Registration",
  new_applications_closed: false,
  new_applications_closed_label: "Sorry, new registrations are closed.",
  hack_hide_logo: true,
  auto_guardian_approval: true,
  email_from: "Skyridge Cheerleading <notifications@mg.letsapply.io>",
  email_replyto: "skyridgefalconscheer@gmail.com",
  email_bcc: ["skyridgefalconscheer@gmail.com"],
  email_help: "skyridgefalconscheer@gmail.com",
  tryout_number_prefix: "HUNDREDS_BY_GRADE",
  submit_ready_text:
    '<p>You can now submit your registration. Click "Review Registration" below to start.</p>',
  review_button_text: "Review Registration",
  submit_review_text:
    '<p><b>You\'re not done yet!</b> Please verify that your answers below are correct and complete, and then click "Submit Registration".</p>',
  submit_button_text: "Submit Registration",
  submitted_text:
    "Registration submitted <b>{{formatDateTime userData.submitted}}</b>",
  application_approved_text: "",
  application_submitted:
    "Your registration has been submitted. Please contact us if you need to make changes.",
  view_application_button_text: "View Registration",
  application_approved_email: {
    subject:
      "Skyridge Mini Cheer & Stunt Crew Camp Registration Submitted for {{userData.name}}",
    html: '<p>Your registration for the 2023 Skyridge Mini Cheer &amp; Stunt Crew Camp has been submitted.</p><p>You do not need to submit any additional paperwork. You can download a copy of your signed forms for your own records here:</p><p><a href="{{pdfUrl}}">Signed registration forms.</a></p><p>- Skyridge Cheer Coaches</p>',
  },
  overview: {
    sections: [
      {
        key: "basic",
        label: "",
        columns: [
          {
            key: "user.number",
            label: "#",
          },
          {
            key: "user.application.name",
            label: "Name",
            link: true,
          },
          {
            key: "user.application.gender",
            label: "Gender",
          },
          {
            key: "user.application.grade",
            label: "Year",
          },
          {
            key: "user.application.guardian_name",
            label: "Parent",
          },
          {
            key: "user.application.guardian_email",
            label: "Parent Email",
          },
          {
            key: "user.application.guardian_phone",
            label: "Parent Phone",
          },
          {
            key: "user.application.shirt_size",
            label: "Shirt Size",
          },
          {
            key: "user.application.referer",
            label: "Referer",
          },
          {
            key: "user.application.submitted",
            label: "Submitted",
            transform: "jsTimestamp",
          },
        ],
      },
    ],
  },
  sections: [
    {
      title: "Basic Information",
      icon: "Person",
      print: false,
      fields: [
        {
          label: "Participant Name",
          key: "name",
          type: "textfield",
          required: true,
          validate: "fullName",
        },
        {
          label: "Email",
          key: "email",
          type: "textfield",
          subtype: "email",
          validate: "email",
          required: true,
        },
        {
          label: "Gender",
          key: "gender",
          type: "select",
          values: ["Male", "Female"],
          required: true,
        },
        {
          label: "Date of Birth",
          key: "birthday",
          type: "date",
          required: true,
          initialFocusedDate: "1/1/2010",
        },
        {
          label: "School Grade for 2023-2024 School Year",
          key: "grade",
          type: "select",
          values: ["Pre-K", "K", "1st", "2nd", "3rd", "4th", "5th", "6th"],
          required: true,
        },
        {
          label: "Parent/Legal Guardian Name",
          key: "guardian_name",
          type: "textfield",
          required: true,
          validate: "fullName",
        },
        {
          label: "Parent/Legal Guardian Email",
          key: "guardian_email",
          type: "textfield",
          subtype: "email",
          validate: "email",
          required: true,
        },
        {
          label: "Parent/Legal Guardian Phone",
          key: "guardian_phone",
          type: "textfield",
          subtype: "tel",
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div>
              <br />
              <br />
              <h3>Emergency Contact</h3>
              <p>Must be someone other than a parent/guardian.</p>
            </div>
          ),
        },
        {
          label: "Emergency Contact Name",
          key: "emergency_contact_name",
          type: "textfield",
          required: true,
          validate: "fullName",
        },
        {
          label: "Relationship",
          key: "emergency_contact_relationship",
          type: "textfield",
          required: true,
        },
        {
          label: "Emergency Contact Phone",
          key: "emergency_contact_phone",
          type: "textfield",
          subtype: "tel",
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div>
              <br />
              <br />
              <h3>Shirt Size</h3>
              <p>
                Shirt sizes will be limited, and distributed based on
                registration time. Please choose your first, second, and third
                choices for shirt size and we will do our best to accommodate.
              </p>
            </div>
          ),
        },
        {
          label: "Shirt Size First Choice",
          key: "shirt_size",
          type: "select",
          values: SHIRT_SIZES,
          required: true,
        },
        {
          label: "Shirt Size Second Choice",
          key: "shirt_size_2",
          type: "select",
          values: ({ userData }) =>
            SHIRT_SIZES.map((ss) => ({
              ...ss,
              disabled: ss.disabled || ss.value === userData.shirt_size,
            })),
          required: true,
        },
        {
          label: "Shirt Size Third Choice",
          key: "shirt_size_3",
          type: "select",
          values: ({ userData }) =>
            SHIRT_SIZES.map((ss) => ({
              ...ss,
              disabled:
                ss.disabled ||
                ss.value === userData.shirt_size ||
                ss.value === userData.shirt_size_2,
            })),
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div>
              <br />
              <br />
              <h3>Referring Cheerleader or Stunt Crew Member</h3>
              <p>Who referred you to Mini Cheer &amp; Stunt Crew Camp?</p>
            </div>
          ),
        },
        {
          label: "Referring Cheerleader or Stunt Crew Member",
          key: "referer",
          type: "select",
          values: [
            "Nobody",
            "Addison Bischoff",
            "Anna Van Orman",
            "Aurora Strongo",
            "Ava Pugmire",
            "Brooke Housley",
            "Brynnley Ford",
            "Bryton Romney",
            "Caroline Broderick",
            "Eden Beck",
            "Emma Meeker",
            "Grace Meeker",
            "Hannah Worthen",
            "Jessica Call",
            "Katie Zabriskie",
            "Kaylen Oswald",
            "Kiley Nielson",
            "Kinlee Avarell",
            "Lauren Peck",
            "Lexi Short",
            "Malia Bye",
            "Olivia Hatch",
            "Railey Smith",
            "Reagan Bischoff",
            "Ruby Smith",
            "Sasha McNeill",
            "Saylor Sharp",
            "Summer Kenyon",
            "Sydney Nelson",
          ],
          required: true,
        },
      ],
    },
    {
      title: "Waiver",
      icon: "Gesture",
      print: true,
      paper: true,
      fields: [
        {
          type: "text",
          text: (
            <>
              <span className="center">
                <h1>
                  Skyridge Mini Cheer &amp; Stunt Crew Camp Informed Consent
                </h1>
              </span>
            </>
          ),
        },
        {
          type: "text",
          text: ({ userData, today }) => (
            <div>
              <p>
                Your child's safety is our top priority. All NFHS Spirit Safety
                Rules will be strictly enforced by the Skyridge Cheer Staff.
              </p>

              <p>
                I understand cheerleading is a vigorous physical activity
                involving motion, rotation, and height in a unique environment.
                As such, my child's participation in cheerleading activity
                carries a higher than ordinary risk of injury.
              </p>

              <p>
                I understand the dangers and risks of cheerleading activity
                include, but are not limited to, serious bodily harm, permanent
                physical impairment, neck injury, spinal injury, catastrophic
                injury, concussion, paralysis, or even death.
              </p>

              <p>
                I voluntarily give my child permission to participate in the
                2023 Skyridge Mini Cheer &amp; Stunt Crew Camp.
              </p>
            </div>
          ),
        },
        {
          type: "signature",
          label: "Guardian Signature",
          key: "informed_consent_guardian_sig",
          required: true,
        },
      ],
    },
    {
      title: "Camp Fee",
      icon: "Money",
      print: false,
      fields: [
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <p>
                Before submitting your registration, you must complete payment.
                All payments and registrations are due before Wednesday, August
                24th at noon. To make a payment:
              </p>

              <ul>
                <li>
                  Go to{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://myschoolfees.com/"
                  >
                    http://myschoolfees.com/
                  </a>
                </li>
                <li>Log in or create a free account</li>
                <li>Select "Skyridge High School"</li>
                <li>Select "Public items"</li>
                <li>Select "Cheer"</li>
                <li>
                  Select "2023 SKYRIDGE MINI CHEER CLINIC:: $50" and add to cart
                </li>
              </ul>
            </div>
          ),
        },
        {
          label: "Check below to acknowledge that you have paid the fee.",
          shortLabel: "Tryout Fee",
          key: "tryout_fee",
          type: "choosemany",
          options: [["paid", "I have paid the $50 camp fee."]],
          required: true,
        },
      ],
    },
  ],
};

export default definition;
