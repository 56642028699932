import React from "react";
import { ThemeProvider, createTheme, alpha } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ConfirmProvider } from "material-ui-confirm";

import loadable from "@loadable/component";
import Loading from "Components/Loading";
import AutoUpdate from "Components/AutoUpdate";
import { DefinitionProvider } from "Hooks/useDefinition";
import { useLocation } from "react-router-dom";
import definitions from "./Definitions";
import NotFound from "Pages/NotFound";

const AsyncIndex = loadable(() => import("Pages/Index"), {
  fallback: <Loading />,
});

const AsyncApproval = loadable(() => import("Pages/Approval"), {
  fallback: <Loading />,
});

const AsyncRecommendation = loadable(() => import("Pages/Recommendation"), {
  fallback: <Loading />,
});

const AsyncApplicationHome = loadable(() => import("Pages/ApplicationHome"), {
  fallback: <Loading />,
});

const AsyncWaiver = loadable(() => import("Pages/Waiver"), {
  fallback: <Loading />,
});

const AsyncWaiverAdmin = loadable(() => import("Pages/WaiverAdmin"), {
  fallback: <Loading />,
});

const AsyncAdmin = loadable(() => import("Pages/Admin"), {
  fallback: <Loading />,
});

const AsyncSiteAdmin = loadable(() => import("Pages/SiteAdmin"), {
  fallback: <Loading />,
});

const theme = createTheme({
  typography: {
    fontSize: 18,
  },
  palette: {
    primary: {
      main: "#e74302",
    },
    secondary: {
      main: "#606972",
    },
    grey: {
      main: grey[300],
      dark: grey[400],
    },
  },
});

const themeWithButton = createTheme(theme, {
  components: {
    MuiListItemButton: {
      defaultProps: {
        disableTouchRipple: true,
        sx: {
          color: "black",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", color: "grey" },
          style: {
            color: theme.palette.getContrastText(theme.palette.grey[300]),
          },
        },
        {
          props: { variant: "outlined", color: "grey" },
          style: {
            color: theme.palette.text.primary,
            borderColor:
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.23)"
                : "rgba(255, 255, 255, 0.23)",
            "&.Mui-disabled": {
              border: `1px solid ${theme.palette.action.disabledBackground}`,
            },
            "&:hover": {
              borderColor:
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.23)"
                  : "rgba(255, 255, 255, 0.23)",
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity
              ),
            },
          },
        },
        {
          props: { color: "grey", variant: "text" },
          style: {
            color: theme.palette.text.primary,
            "&:hover": {
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity
              ),
            },
          },
        },
      ],
    },
  },
});

function DefinitionLoader({ children }) {
  const location = useLocation();

  let definition;
  const locComps = location.pathname.split("/");
  if (locComps.length > 2 && locComps[1] === "app") {
    const applicationId = locComps[2];
    definition = definitions[applicationId];
  }

  return <DefinitionProvider value={definition}>{children}</DefinitionProvider>;
}

function App() {
  return (
    <div className="mb-16">
      <BrowserRouter>
        <CssBaseline />
        <DefinitionLoader>
          <ThemeProvider theme={themeWithButton}>
            <ConfirmProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <AutoUpdate />

                <div className="App-Container">
                  <Routes>
                    <Route path="/admin" element={<AsyncSiteAdmin />} />
                    <Route
                      path="/app/:applicationId/admin/*"
                      element={<AsyncAdmin />}
                    />
                    <Route
                      path="/app/:applicationId/*"
                      element={<AsyncApplicationHome />}
                    />
                    <Route
                      path="/rec/:recToken"
                      element={<AsyncRecommendation />}
                    />
                    <Route
                      path="/approval/:token"
                      element={<AsyncApproval />}
                    />
                    <Route
                      path="/waiver/:waiverId/admin/*"
                      element={<AsyncWaiverAdmin />}
                    />
                    <Route
                      path="/waiver/:waiverId/*"
                      element={<AsyncWaiver />}
                    />
                    <Route path="/" element={<AsyncIndex />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </div>
              </LocalizationProvider>
            </ConfirmProvider>
          </ThemeProvider>
        </DefinitionLoader>
      </BrowserRouter>
    </div>
  );
}

export default App;
