import React from "react";
import { createContext, useContext } from "react";
import { Definition } from "Definitions";

const DefinitionContext = createContext(null);

export const DefinitionProvider = DefinitionContext.Provider;

Definition.prototype.t_h = function () {
  const value = this.t.apply(this, arguments);
  return <span dangerouslySetInnerHTML={{ __html: value }} />;
};

Definition.prototype.tf_h = function () {
  const value = this.tf.apply(this, arguments);
  return <span dangerouslySetInnerHTML={{ __html: value }} />;
};

export default function useDefinition() {
  const definition = useContext(DefinitionContext);

  return definition;
}
