import { initializeApp } from "firebase/app";
import {
  getFirestore,
  enableMultiTabIndexedDbPersistence,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import "firebase/storage";

const useSimulators =
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_USE_PRODUCTION_FIREBASE !== "1";

// Initialize Firebase
export const config = {
  apiKey: "AIzaSyDQxFyQE53q8mlPUEUGN4HPp70EAFg0Kr4",
  authDomain: "cats-cf583.firebaseapp.com",
  databaseURL: "https://cats-cf583.firebaseio.com",
  projectId: "cats-cf583",
  storageBucket: useSimulators
    ? process.env.REACT_APP_DEV_STORAGE_BUCKET
    : "cats-cf583.appspot.com",
  messagingSenderId: "1090852684620",
  clientId:
    "1090852684620-90pdu7j7r8hccqll18lggt17o0buskbf.apps.googleusercontent.com",
};
initializeApp(config);

if (useSimulators) {
  connectAuthEmulator(getAuth(), "http://localhost:9099/");
  connectFirestoreEmulator(getFirestore(), "localhost", 8080);
  connectFunctionsEmulator(getFunctions(), "localhost", 5001);
  connectDatabaseEmulator(getDatabase(), "localhost", 9000);
}

if (window.localStorage.getItem("useExperimentalOfflineCaching") === "true") {
  const firestore = getFirestore();
  enableMultiTabIndexedDbPersistence(firestore)
    .then(() => {
      console.log("enabled persistence");
    })
    .catch((e) => {
      console.log("failed enabling persistence", e);
    });
}
