import { useConfirm } from "material-ui-confirm";
import { getAuth, signOut } from "firebase/auth";
import Button from "@mui/material/Button";

export default function SignOutButton(props) {
  const confirm = useConfirm();
  const auth = getAuth();

  if (!auth.currentUser) return null;

  async function handleSignOut() {
    try {
      await confirm({
        title: "Are you sure?",
        description: "This will sign you out of the application.",
        confirmationText: "Sign Out",
      });
      signOut(auth);
    } catch (e) {
      console.log("canceled");
    }
  }

  return (
    <Button variant="contained" color="grey" onClick={handleSignOut} {...props}>
      Sign Out
    </Button>
  );
}
