import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./Utils/firebase"; // import this early!
import * as Sentry from "@sentry/browser";
import version from "Utils/version";

import App from "./App";

Sentry.init({
  dsn: "https://6d8402dec51e42b7a6dc886d9fc318a5@sentry.io/1408647",
  release: `cats@${version()}`,
  environment: process.env.NODE_ENV,
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);
