import React from "react";
import get from "lodash/get";

const required_fees = {
  fee_activity: 550,
};

function selectedFees(userData) {
  return Object.keys(required_fees).reduce((acc, key) => {
    if (userData[key] === "yes") {
      acc[key] = required_fees[key];
    }
    return acc;
  }, {});
}

function totalFee(userData) {
  const fees = selectedFees(userData);
  const values = Object.keys(fees).map((f) => fees[f]);
  return values.reduce((acc, v) => acc + v, 0);
}

const definition = {
  due_at_label: "",
  title: "Skyridge Student Council Application 2022",
  start_introduction:
    '<p>Thanks for your interest in the 2022-2023 Skyridge Student Council!</p><p>This application will work best from your phone. Your answers will be saved as you go, and you can leave and return anytime to continue.</p><p><i>If you have already started an application, you probably signed in using a different Google account than <b>{{email}}</b>. If so, please sign out with the "SIGN OUT" button below and try again with the proper account. Continuing here will start a blank application.</i></p>',
  auto_guardian_approval: false,
  email_from: "Skyridge Student Council <notifications@mg.letsapply.io>",
  email_replyto: "ggriffith@alpinedistrict.org",
  email_bcc: [
    "zach+stuco2022@zwily.com",
    "ggriffith+stuco2022@alpinedistrict.org",
  ],
  email_help: "zach@zwily.com",
  tryout_number_prefix: "DEFAULT",
  application_approved_sms:
    "Your Skyridge Student Council application has been approved by your parent/guardian.",
  recommendation_submitted_sms:
    "{{email}} submitted your Skyridge Student Council Recommendation. {{#if remaining}}{{remaining}} left until done.{{else}}All recommendations are done!{{/if}}",

  application_approved_text:
    'Your application number is <span class="user-number">{{userData.number}}</span>',

  application_approved_email: {
    subject:
      "Skyridge Student Council Application Submitted for {{userData.name}}",
    html: '<p>Your application for the 2022-2023 Skyridge Student Council is now submitted and approved by your parent or legal guardian.</p><p>You can download a copy of your signed forms for your own records here:</p><p><a href="{{pdfUrl}}">Signed application forms.</a></p><p>Please make sure that all your recommendations are completed as soon as possible.  You can track the status of your submitted recommendations <a href="{{appUrl}}">at the website</a>.</p><p>- Gig Griffith</p>',
  },
  recommendation_email: {
    subject:
      "{{#if isFollowUp}}[Reminder] {{/if}}Recommendation Request for {{userData.name}} (Skyridge Student Council)",
    html: '{{#if isFollowUp}}<p><i>This is a follow-up. If you have already completed a recommendation for this student, please disregard this. Thanks!</i></p>{{/if}}<p>Hello!</p><p><b>{{userData.name}}</b> is applying for Student Council at Skyridge High School for the 2022-2023 school year, and has indicated that you are one of their teachers.</p><p>We ask for recommendations from current teachers for each Student Council candidate. We have prepared a survey for you that should take no longer than a few minutes.</p><p><b><a href="{{url}}">Click here to complete recommendation.</a></b></p> <p>Thanks for your help!</p>',
  },
  approval_request_email: {
    subject:
      "APPROVAL NEEDED: Skyridge Student Council Application for {{userData.name}}",
    html: '<p>{{userData.name}} has submitted an application for the Skyridge Student Council.</p><p>You were listed as the parent or legal guardian, and your approval is needed  to complete the application process. Please click below to view and approve the application:</p><p><a href="{{url}}">View Application</a></p> <p>Thank you!</p>',
  },
  overview: {
    sections: [
      {
        key: "basic",
        label: "",
        columns: [
          {
            key: "user.number",
            label: "#",
          },
          {
            key: "user.application.name",
            label: "Name",
            link: true,
            hover: (data) => {
              if (get(data, "user.application.headshot[0].url")) {
                return (
                  <img
                    alt="headshot"
                    style={{ width: "auto", height: "400px" }}
                    src={get(data, "user.application.headshot[0].url")}
                  />
                );
              } else {
                return "No headshot";
              }
            },
          },
          {
            key: "user.application.birthday",
            label: "Bday",
            sortBy: (u) => {
              if (!u.user.application.birthday) {
                return null;
              }

              const c = u.user.application.birthday.split("/");
              const month = Number(c[0]);
              const day = Number(c[1]);
              return `${month < 10 ? `0${month}` : month}/${
                day < 10 ? `0${day}` : day
              }`;
            },
          },
          {
            key: "user.application.grade",
            label: "Year",
          },
          {
            key: "user.assessments.gpa",
            label: "GPA",
          },
          {
            key: "user.assessments.essay_grade",
            label: "Essay",
          },
          {
            key: "user.application.position",
            label: "Position",
            width: "200px",
            hover: "user.application.position",
          },
        ],
      },
      {
        key: "recommendations",
        label: "Recommendations",
        columns: [
          {
            key: "user.recommendations.attitude.mean",
            label: "Attitude",
            hover: "user.recommendations.attitude.summary",
            rotated: true,
            gradient: {
              max: 5,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.recommendations.academia.mean",
            label: "Academia",
            hover: "user.recommendations.academia.summary",
            rotated: true,
            gradient: {
              max: 5,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.recommendations.sociability.mean",
            label: "Sociability",
            hover: "user.recommendations.sociability.summary",
            rotated: true,
            gradient: {
              max: 5,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.recommendations.ambition.mean",
            label: "Ambition",
            hover: "user.recommendations.ambition.summary",
            rotated: true,
            gradient: {
              max: 5,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.recommendations.reliability.mean",
            label: "Reliability",
            hover: "user.recommendations.reliability.summary",
            rotated: true,
            gradient: {
              max: 5,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.recommendations.enthusiasm.mean",
            label: "Enthusiasm",
            hover: "user.recommendations.enthusiasm.summary",
            rotated: true,
            gradient: {
              max: 5,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.recommendations.recommended.no.count",
            label: "'No' Rec",
            hover: "user.recommendations.recommended_no",
            highlight: true,
            rotated: true,
          },
          {
            key: "user.recommendations.disciplinary_procedures.yes.count",
            label: "Discipline",
            hover: "user.recommendations.disciplinary_procedures_explanation",
            highlight: true,
            rotated: true,
          },
          {
            key: "user.recommendations.comments.length",
            label: "Comments",
            hover: "user.recommendations.comments",
            rotated: true,
          },
        ],
      },
    ],
  },
  lists: {
    teachersBySchool: require("./teachersBySchool2022.json"),
  },
  sections: [
    {
      title: "Basic Information",
      icon: "Person",
      print: false,
      fields: [
        {
          label: "Name",
          key: "name",
          type: "textfield",
          required: true,
          validate: "fullName",
        },
        {
          label: "Email",
          key: "email",
          type: "textfield",
          subtype: "email",
          validate: "email",
          required: true,
        },
        {
          label: "Phone",
          key: "phone",
          type: "textfield",
          subtype: "tel",
          required: true,
        },
        {
          label: "Gender",
          key: "gender",
          type: "select",
          values: ["Male", "Female", "Other"],
          required: true,
        },
        {
          label: "Date of Birth",
          key: "birthday",
          type: "date",
          required: true,
          initialFocusedDate: "1/1/2005",
        },
        {
          label: "Parent/Legal Guardian Name",
          key: "guardian_name",
          type: "textfield",
          required: true,
          validate: "fullName",
        },
        {
          label: "Parent/Legal Guardian Email",
          key: "guardian_email",
          type: "textfield",
          subtype: "email",
          validate: "email",
          required: true,
        },
        {
          label: "Parent/Legal Guardian Phone",
          key: "guardian_phone",
          type: "textfield",
          subtype: "tel",
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <p>
              What other School or Outside Activities are you involved in that
              may compete for your Student Council time or attention? Note –
              this is not necessarily a bad thing, we want StuCo members to have
              diverse representation of involvement in the school community.
            </p>
          ),
        },
        {
          label: "Other activities",
          key: "other_activities",
          type: "textfield",
          multiline: true,
          rows: 4,
          required: true,
          variant: "outlined",
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <>
              <p>What position are you applying for?</p>
            </>
          ),
        },
        {
          label: "Position",
          key: "position",
          type: "textfield",
          required: true,
          variant: "outlined",
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <>
              <p>
                <i>
                  Thank you for your interest in serving! Based on this answer,
                  the Elections Committee will reach out to you for an
                  interview.
                </i>
              </p>
            </>
          ),
        },
      ],
    },
    {
      title: "School Information",
      icon: "School",
      print: false,
      fields: [
        {
          label: "Grade for 2022-2023 School Year",
          key: "grade",
          type: "select",
          values: ["10th", "11th", "12th"],
          required: true,
        },
        {
          label: "Current School",
          key: "school",
          type: "select",
          other: "Current School Name",
          values: ["Skyridge High School"],
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div>
              <br />
              <h3>Current Grades</h3>
              <p>
                Please upload a screenshot or picture of your grades so far this
                year on Skyward (or equivalent system if your school does not
                use Skyward.)
              </p>
            </div>
          ),
        },
        {
          type: "upload",
          label: "Grades",
          key: "grades",
          required: true,
          maxFiles: 3,
          accept:
            "image/*, .heic, .hevc, .heif, .pdf, .png, .gif, .jpg, .jpeg, .doc, .docx, image/png, image/jpg, image/jpeg, image/gif, application/msword, application/pdf",
          showImage: false,
          assessments: [
            {
              key: "gpa",
              label: "GPA",
              type: "textfield",
            },
          ],
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div>
              <br />
              <h3>Attendance</h3>
              <p>
                Please upload a screenshot or picture of your attendance (the "
                <b>2022-2023 Totals</b>" tab) from Skyward (or equivalent system
                if your school does not use Skyward.)
              </p>
            </div>
          ),
        },
        {
          type: "upload",
          label: "Attendance",
          key: "attendance",
          required: true,
          maxFiles: 3,
          accept:
            "image/*, .heic, .hevc, .heif, .pdf, .png, .gif, .jpg, .jpeg, .doc, .docx, image/png, image/jpg, image/jpeg, image/gif, application/msword, application/pdf",
          showImage: false,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div>
              <br />
              <h3>Teachers</h3>
              <p>
                Please select your teacher for each period. If your teacher is
                not listed, choose "Other" and enter the teacher's email address
                into the field below. We will be contacting all teachers for
                recommendations.
              </p>
            </div>
          ),
        },
        {
          type: "select",
          key: "teacher_a1",
          label: "A1 Teacher",
          other: "A1 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
        {
          type: "select",
          key: "teacher_a2",
          label: "A2 Teacher",
          other: "A2 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
        {
          type: "select",
          key: "teacher_a3",
          label: "A3 Teacher",
          other: "A3 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
        {
          type: "select",
          key: "teacher_a4",
          label: "A4 Teacher",
          other: "A4 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
        {
          type: "select",
          key: "teacher_b5",
          label: "B5 Teacher",
          other: "B5 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
        {
          type: "select",
          key: "teacher_b6",
          label: "B6 Teacher",
          other: "B6 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
        {
          type: "select",
          key: "teacher_b7",
          label: "B7 Teacher",
          other: "B7 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
        {
          type: "select",
          key: "teacher_b8",
          label: "B8 Teacher",
          other: "B8 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
      ],
    },
    {
      title: "Experience",
      icon: "Poll",
      print: false,
      fields: [
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <p>
              Please briefly describe any past leadership experience you’ve had
              in the past.
            </p>
          ),
        },
        {
          label: "Experience",
          key: "experience",
          type: "textfield",
          multiline: true,
          rows: 6,
          required: true,
          variant: "outlined",
        },
        {
          type: "text",
          text: (
            <div style={{ marginTop: "30px" }}>
              <p>
                Describe a time when you failed and you recovered? What did you
                learn?
              </p>
            </div>
          ),
        },
        {
          label: "Essay",
          key: "essay1",
          type: "textfield",
          multiline: true,
          rows: 10,
          required: true,
          variant: "outlined",
        },
        {
          type: "text",
          text: (
            <div style={{ marginTop: "30px" }}>
              <p>What does it mean to be a Skyridge Falcon?</p>
            </div>
          ),
        },
        {
          label: "Essay",
          key: "essay2",
          type: "textfield",
          multiline: true,
          rows: 10,
          required: true,
          variant: "outlined",
          assessments: [
            {
              key: "essay_grade",
              label: "Essay Grade",
              type: "textfield",
            },
          ],
        },
      ],
    },
    {
      title: "Headshot",
      icon: "Camera",
      print: false,
      wrapperStyle: { margin: "45px 0 0", padding: "0 0 20px 0" },
      fields: [
        {
          type: "upload",
          label: "Headshot",
          key: "headshot",
          required: true,
          accept: "image/*",
          showImage: true,
        },
      ],
    },
    {
      title: "Student Pledge",
      icon: "Gesture",
      paper: true,
      print: true,
      fields: [
        {
          type: "text",
          printOnly: true,
          text: (
            <>
              <span className="center">
                <h1>Student Pledge</h1>
              </span>
              <hr />
            </>
          ),
        },
        {
          type: "text",
          text: (
            <>
              <p className="justify line-height-2x">
                If I am elected to this position as an officer and Student
                Council Member, I pledge to:
              </p>
              <ul>
                <li>
                  Strive to the best of my ability to represent my school and
                  community well in all that I do.{" "}
                </li>
                <li>
                  I will attend Leadership Camp completely from{" "}
                  <b>June 6 through June 10</b>
                  at Utah State University.
                </li>
                <li>
                  Recognize that my position is contingent upon my academic
                  success and I intend to maintain a GPA of 3.0 or better each
                  quarter. *Grades will be turned in to StuCo Advisor at the
                  close of each quarter. I must have no unexcused absences and
                  any tardies approved by Stuco Advisor.
                </li>
                <li>
                  Recognize that my position is contingent upon my behavior and
                  that my position will be forfeited due to administrative
                  disciplinary referrals and or any suspensions/expulsions.
                </li>
                <li>
                  Pay the $550 Activity Fee. This covers over $600 of cost for
                  conferences, sweaters, and supplies.
                </li>
                <li>
                  Attend every StuCo Class, mandatory meetings and Council
                  sponsored events whenever they are scheduled (Never on Sundays
                  or Monday evenings).
                </li>
                <li>
                  Be enrolled in at least 6 Skyridge High classes and physically
                  be on campus at least 3 periods each school day.
                </li>
                <li>
                  Meet and often exceed expectations and responsibilities in
                  position and committees as elected/assigned.
                </li>
              </ul>
              <p>
                The above pledge may be adjusted to meet a Skyridge High team,
                performance or club requirement. Outside work is not an
                excusable reason ever for missing Stuco events and Family
                obligations may not impede attendance at Summer Conference.
              </p>
            </>
          ),
        },
        {
          type: "signature",
          label: "Student Signature",
          key: "student_pledge_student_sig",
          required: true,
        },
        {
          type: "signature",
          label: "Guardian Signature",
          key: "student_pledge_guardian_sig",
          required: true,
        },
      ],
    },
    {
      title: "Parent/Guardian Pledge",
      icon: "Gesture",
      print: true,
      paper: true,
      fields: [
        {
          type: "text",
          printOnly: true,
          text: (
            <>
              <span className="center">
                <h1>Parent/Guardian Pledge</h1>
              </span>
              <hr />
            </>
          ),
        },
        {
          type: "text",
          text: ({ userData, today }) => (
            <div className="justify">
              <p>
                I pledge to support{" "}
                <b>
                  <u>&nbsp; &nbsp;{userData.name}&nbsp; &nbsp;</u>
                </b>{" "}
                to the best of my ability as he/she commits to the SHS Student
                Council Position. Student Council should be his/her job this
                year with after school employment postponed to another year if
                possible. I will provide them with transportation to meetings
                and activities or allow alternative methods to be used when
                necessary. School bus transportation is only for Summer
                Leadership Conference. I understand that communication within
                the council relies on cell phones, social media sites and email
                and will allow that communication to take place.
              </p>
              <p>I have read and agree with the terms in the Student Pledge.</p>
            </div>
          ),
        },
        {
          type: "signature",
          label: "Guardian Signature",
          key: "guardian_pledge_guarding_sig",
          required: true,
        },
      ],
    },
    {
      title: "Fee Transparency Form",
      icon: "Gesture",
      print: true,
      paper: true,
      main_signature_field: "sig_fee_guardian",
      fields: [
        {
          type: "text",
          text: (
            <>
              <span style={{ textAlign: "center" }} className="center">
                <h3>FEE TRANSPARENCY FORM</h3>
                <h4>Skyridge High School</h4>
                <p>3000 North Center Street</p>
                <p>
                  John Wallwork, <i>Principal</i>
                </p>
              </span>
              <hr />
              <div>Extra-Curricular Activity: Student Council</div>
              <hr />
              <h4>Board Approved Fees:</h4>
              <p className="smaller">
                <i>
                  Payments can be made in the SHS Financial Office or at{" "}
                  <a href="http://onestop.alpineschools.org">
                    http://onestop.alpineschools.org
                  </a>
                  .
                </i>
              </p>
            </>
          ),
        },
        {
          type: "fee",
          amount: required_fees.fee_activity,
          name: "Activity Fee",
          help: "Charged at fall school registration",
          key: "fee_activity",
          resetOnChange: ["sig_fee_guardian", "sig_fee_student"],
          required: true,
          mandatory: true,
        },
        {
          type: "text",
          text: (
            <>
              <p>Student Council Fees go to pay for the following:</p>
              <ul>
                <li>
                  Leadership Conference during June (USU) or July (Dixie)
                  student cost is $345-380.
                </li>
                <li>Uniform (Student Council Sweater) is $205.</li>
                <li>
                  Various other recognitions, dinners, and class activity costs
                  are covered under council budget.
                </li>
              </ul>
              <p>
                Optional costs may include Stuco hat or work shirts at
                approximately $20 each.
              </p>
            </>
          ),
        },
        {
          type: "text",
          printType: "feeTotal",
          total: ({ userData }) => totalFee(userData),
          text: ({ userData }) => (
            <>
              <hr />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.3em",
                    margin: "auto 0",
                    flexGrow: 1,
                  }}
                >
                  TOTAL DUE:
                </div>
                <div
                  className="fee-total"
                  style={{
                    margin: "auto",
                    textAlign: "center",
                    paddingRight: "10px",
                    fontSize: "1.5em",
                  }}
                >
                  <b> ${totalFee(userData)}</b>
                </div>
              </div>
            </>
          ),
        },
        {
          type: "text",
          text: ({ userData }) => (
            <>
              <hr />
              <div>
                Participant's Name: <b>{userData.name}</b>
              </div>
              <br />
              <br />
            </>
          ),
        },
        {
          type: "signature",
          label: "Guardian Signature",
          key: "sig_fee_guardian",
          required: true,
          prereq_fields: Object.keys(required_fees),
          prereq_message: "Please select an option on each fee before signing.",
          capturedFields: ({ userData }) => selectedFees(userData),
        },
        {
          type: "signature",
          label: "Student Signature",
          key: "sig_fee_student",
          required: true,
          prereq_fields: Object.keys(required_fees),
          prereq_message: "Please select an option on each fee before signing.",
          capturedFields: ({ userData }) => selectedFees(userData),
        },
      ],
    },
    {
      title: "Recommendations",
      recommendations: true,
      icon: "ThumbUp",
      print: false,
      hideOnPreview: true,
      fields: [
        {
          type: "recommendations",
          recommendationFields: {
            teacher_a1: "A1 Teacher",
            teacher_a2: "A2 Teacher",
            teacher_a3: "A3 Teacher",
            teacher_a4: "A4 Teacher",
            teacher_b5: "B5 Teacher",
            teacher_b6: "B6 Teacher",
            teacher_b7: "B7 Teacher",
            teacher_b8: "B8 Teacher",
          },
        },
      ],
    },
  ],
};

export default definition;
