import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import useFlagAfterDelay from "Hooks/useFlagAfterDelay";

export default function Loading() {
  const showLoadingIndicator = useFlagAfterDelay(1000);
  const showMoreText = useFlagAfterDelay(5000);

  return (
    <div
      style={{
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        opacity: 0.7,
      }}
    >
      <CircularProgress
        size={24}
        color="secondary"
        style={{
          visibility: showLoadingIndicator ? "visible" : "hidden",
          margin: "24px",
        }}
      />
      <Typography color="secondary" variant="h5">
        {showMoreText ? "Still loading..." : "Loading..."}
      </Typography>
    </div>
  );
}
