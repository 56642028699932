import { useState, useEffect } from "react";

export default function useFlagAfterDelay(delay) {
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFlag(true);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return flag;
}
