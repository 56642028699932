import React from "react";
import { getDatabase, ref } from "firebase/database";
import { useObjectVal } from "react-firebase-hooks/database";
import version from "Utils/version";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";

export default function AutoUpdate() {
  const versionRef = ref(getDatabase(), "/public/version");
  const [latestVersion] = useObjectVal(versionRef);
  const currentVersion = version().split("-")[0];
  if (!latestVersion) {
    return null;
  }

  const l = latestVersion.split(".").map((n) => parseInt(n, 10));
  const c = currentVersion.split(".").map((n) => parseInt(n, 10));

  // Do nothing if major and minor versions are the same.
  if (l[0] === c[0] && l[1] === c[1]) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={true}
      onClose={undefined}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
      message={<span id="message-id">Update available!</span>}
      action={[
        <Button
          key="reload"
          color="primary"
          size="small"
          onClick={() => window.location.reload(true)}
        >
          RELOAD
        </Button>,
      ]}
    />
  );
}
