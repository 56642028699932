import React from "react";

import Typography from "@mui/material/Typography";
import get from "lodash/get";

const required_fees = {
  fee_participation: 100,
  fee_uniforms: 900,
  fee_camp: 350,
};

const optional_fees = {
  fee_nationals: 1000,
};

function selectedFees(userData) {
  return Object.keys(required_fees).reduce((acc, key) => {
    if (userData[key] === "yes") {
      acc[key] = required_fees[key];
    }
    return acc;
  }, {});
}

function totalFee(userData) {
  const fees = selectedFees(userData);
  const values = Object.keys(fees).map((f) => fees[f]);
  return values.reduce((acc, v) => acc + v, 0);
}

const definition = {
  due_at_label: "Applications are due before tryouts on <b>March 28, 2023</b>",
  title: "Skyridge Cheer & Stunt Crew Application 2023",
  start_introduction:
    '<p>Thanks for your interest in the 2023-2024 Skyridge Cheerleading and Stunt Crew teams!</p><p>This application will work best from your phone. Your answers will be saved as you go, and you can leave and return anytime to continue.</p><p><i>If you have already started an application, you probably signed in using a different Google account than <b>{{email}}</b>. If so, please sign out with the "SIGN OUT" button below and try again with the proper account. Continuing here will start a blank application.</i></p>',
  auto_guardian_approval: false,
  email_from: "Skyridge Cheerleading <notifications@mg.letsapply.io>",
  email_replyto: "skyridgefalconscheer@gmail.com",
  email_bcc: ["skyridgefalconscheer@gmail.com"],
  email_help: "skyridgefalconscheer@gmail.com",
  tryout_number_prefix: "PREFIX_M_FOR_MALES",
  application_approved_sms:
    "Your Skyridge Cheer & Stunt Crew application has been approved by your parent/guardian. Your tryout number is #{{userNumber}}.",
  recommendation_submitted_sms:
    "{{email}} submitted your Skyridge Cheer & Stunt Crew Recommendation. {{#if remaining}}{{remaining}} left until done.{{else}}All recommendations are done!{{/if}}",
  application_approved_email: {
    subject:
      "Skyridge Cheer & Stunt Crew Application Submitted for {{userData.name}}",
    html: '<p>Your application for the 2023-2024 Skyridge Cheer and Stunt Crew team is now submitted and approved by your parent or legal guardian.</p><p>Your assigned tryout number is {{userNumber}}.</p><p>You do not need to submit any additional paperwork. You can download a copy of your  signed forms for your own records here:</p><p><a href="{{pdfUrl}}">Signed application forms.</a></p><p>Please make sure that all your recommendations are completed as soon as possible.  You can track the status of your submitted recommendations <a href="{{appUrl}}">at the website</a>.</p><p>- Skyridge Cheer Coaches</p>',
  },
  recommendation_email: {
    subject:
      "{{#if isFollowUp}}[Reminder] {{/if}}Recommendation Request for {{userData.name}} (Skyridge Cheerleading)",
    html: '{{#if isFollowUp}}<p><i>This is a follow-up. If you have already completed a recommendation for this student, please disregard this. Thanks!</i></p>{{/if}}<p>Hello!</p><p><b>{{userData.name}}</b> is trying out for the Skyridge Cheerleading and Stunt Crew Team at Skyridge High School for the 2023-2024 school year, and has indicated that you are a teacher or past coach.</p><p>We ask for recommendations from current teachers and past coaches for each cheer or stunt crew candidate. We have prepared a survey for you that should take no longer than a few minutes.</p><p><b><a href="{{url}}">Click here to complete recommendation.</a></b></p> <p>Thanks for your help!</p><p>- Skyridge Cheer Coaches</p>',
  },
  approval_request_email: {
    subject:
      "APPROVAL NEEDED: Skyridge Cheerleading Application for {{userData.name}}",
    html: '<p>{{userData.name}} has submitted an application for the Skyridge Cheerleading and  Stunt Crew Team.</p><p>You were listed as the parent or legal guardian, and your approval is needed  to complete the application process. Please click below to view and approve the application:</p><p><a href="{{url}}">View Application</a></p> <p>Thank you!</p><p>- Skyridge Cheer Coaches</p>',
  },
  placements: {},
  tryout_pages: {
    additional_fields: [
      {
        value: (userData) => (
          <div
            style={{
              clear: "right",
              paddingTop: "25px",
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: "110px",
                    width: "150px",
                    border: "2px solid #e74302",
                    borderRadius: "10px",
                  }}
                />
                <span
                  style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                  }}
                >
                  Final Score
                </span>
              </div>

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      height: "50px",
                      width: "100px",
                      border: "2px solid #606972",
                      borderRadius: "10px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "1.0rem",
                      fontWeight: "bold",
                      paddingLeft: "10px",
                    }}
                  >
                    Cheer
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "50px",
                      width: "100px",
                      border: "2px solid #606972",
                      borderRadius: "10px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "1.0rem",
                      fontWeight: "bold",
                      paddingLeft: "10px",
                    }}
                  >
                    Dance
                  </span>
                </div>
              </div>

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      height: "50px",
                      width: "100px",
                      border: "2px solid #606972",
                      borderRadius: "10px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "1.0rem",
                      fontWeight: "bold",
                      paddingLeft: "10px",
                    }}
                  >
                    Jumps
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "50px",
                      width: "100px",
                      minWidth: "100px",
                      border: "2px solid #606972",
                      borderRadius: "10px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "1.0rem",
                      fontWeight: "bold",
                      paddingLeft: "10px",
                    }}
                  >
                    Motions
                  </span>
                </div>
              </div>
            </div>

            <div className="stunt-grid">
              <div
                className={`${
                  (userData.stunt_positions || {})["backspot"]
                    ? "experience"
                    : ""
                } ${
                  userData.stunt_best_position === "backspot" ? "best" : ""
                } ${
                  userData.stunt_favorite_position === "backspot"
                    ? "favorite"
                    : ""
                }`}
              >
                Backspot
              </div>
              <div
                className={`${
                  (userData.stunt_positions || {})["flier"] ? "experience" : ""
                } ${userData.stunt_best_position === "flier" ? "best" : ""} ${
                  userData.stunt_favorite_position === "flier" ? "favorite" : ""
                }`}
              >
                Flyer
              </div>
              <div
                className={`${
                  (userData.stunt_positions || {})["main_base"]
                    ? "experience"
                    : ""
                } ${
                  userData.stunt_best_position === "main_base" ? "best" : ""
                } ${
                  userData.stunt_favorite_position === "main_base"
                    ? "favorite"
                    : ""
                }`}
              >
                Main Base
              </div>
              <div
                className={`${
                  (userData.stunt_positions || {})["secondary_base"]
                    ? "experience"
                    : ""
                } ${
                  userData.stunt_best_position === "secondary_base"
                    ? "best"
                    : ""
                } ${
                  userData.stunt_favorite_position === "secondary_base"
                    ? "favorite"
                    : ""
                }`}
              >
                Secondary
              </div>
            </div>

            <br />

            <div className="flex flex-row">
              <div
                className="flex flex-row flex-1"
                style={{ width: "75%", minWidth: "75%" }}
              >
                <div>
                  <table className="tumbling-table " cellSpacing="2">
                    <thead>
                      <tr>
                        <th className="bg-gray-100 rounded-md" colSpan="3">
                          Standing
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        ["Bridge", "st_bridge"],
                        ["BHS", "st_bhs"],
                        ["BHS #", "st_m_bhs"],
                        ["Tuck", "st_tuck"],
                        ["Full", "st_full"],
                      ].map(([name, key]) => (
                        <tr>
                          <th>{name}</th>
                          <td
                            className={`tumbling-cell tumbling-cell-${userData[
                              key
                            ]?.replaceAll("+", "")}`}
                          >
                            {userData[key]}
                          </td>
                          <td className="box"></td>
                        </tr>
                      ))}
                      <tr>
                        <th colSpan="2">3 Tucks</th>
                        <td className="box"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div>
                  <table className="ml-4 tumbling-table">
                    <thead>
                      <tr className="">
                        <th className="bg-gray-100 rounded-md" colSpan="3">
                          Running
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        ["CW", "rt_cartwheel"],
                        ["RO BHS", "rt_ro_bhs"],
                        ["RO BHS BHS", "rt_ro_multiple_bhs"],
                        ["CW BT", "rt_cartwheel_bt"],
                        ["RO BHS BT", "rt_ro_bhs_bt"],
                        ["RO BHS L", "rt_ro_bhs_l"],
                        ["RO BHS L SO", "rt_ro_bhs_l_so"],
                        ["RO BHS F", "rt_ro_bhs_full"],
                      ].map(([name, key]) => (
                        <tr>
                          <th>{name}</th>
                          <td
                            className={`tumbling-cell tumbling-cell-${userData[
                              key
                            ]?.replaceAll("+", "")}`}
                          >
                            {userData[key]}
                          </td>
                          <td className="box"></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div>
                  <table className="ml-4 tumbling-table">
                    <tbody>
                      <tr>
                        <th className="bg-gray-100 rounded-md" colSpan="3">
                          Splits
                        </th>
                      </tr>
                      <tr>
                        <th colSpan="2">Left</th>
                        <td className="box"> </td>
                      </tr>
                      <tr>
                        <th colSpan="2">Middle</th>
                        <td className="box"> </td>
                      </tr>
                      <tr>
                        <th colSpan="2">Right</th>
                        <td className="box"> </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="flex flex-col flex-1">
                <table>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          fontSize: "1.5em",
                          textAlign: "right",
                        }}
                      >
                        Height:
                      </td>
                      <td
                        style={{
                          fontSize: "2em",
                          whiteSpace: "nowrap",
                          width: "1%",
                          paddingLeft: "10px",
                        }}
                      >
                        <b>{userData.stunt_height}</b>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontSize: "1.5em",
                          textAlign: "right",
                        }}
                      >
                        Reach:
                      </td>
                      <td
                        style={{
                          fontSize: "2em",
                          whiteSpace: "nowrap",
                          width: "1%",
                          paddingLeft: "10px",
                        }}
                      >
                        <b>{userData.stunt_hand_reach}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex h-full pt-2">
              <span className="font-bold">Absences:</span>{" "}
              <span className="">
                {userData.dates_absent?.split("\n").join(" ")}
              </span>
            </div>
          </div>
        ),
      },
    ],
  },
  overview: {
    sections: [
      {
        key: "basic",
        label: "",
        columns: [
          {
            key: "user.number_and_progress",
            label: "#",
          },
          {
            key: "user.application.name",
            label: "Name",
            link: true,
            hover: (data) => {
              if (get(data, "user.application.headshot[0].url")) {
                return (
                  <img
                    alt="headshot"
                    style={{ width: "auto", height: "400px" }}
                    src={get(data, "user.application.headshot[0].url")}
                  />
                );
              } else {
                return "No headshot";
              }
            },
          },
          {
            key: "user.application.birthday",
            label: "Bday",
            sortBy: (u) => {
              if (!u.user.application.birthday) {
                return null;
              }

              const c = u.user.application.birthday.split("/");
              const month = Number(c[0]);
              const day = Number(c[1]);
              return `${month < 10 ? `0${month}` : month}/${
                day < 10 ? `0${day}` : day
              }`;
            },
          },
          {
            key: "user.application.grade",
            label: "Year",
          },
          {
            key: "user.application.dates_absent",
            label: "Absences",
            width: "200px",
            hover: "user.application.dates_absent",
            hover_style: {
              whiteSpace: "pre-wrap",
            },
          },
          {
            key: "user.assessments.team",
            label: "Team",
          },
          {
            key: "user.assessments.gpa",
            label: "GPA",
          },
          {
            key: "user.assessments.essay_grade",
            label: "Essay",
          },
        ],
      },
      {
        key: "standing_tumbling",
        label: "Standing Tumbling",
        hover: "user.application.st_comments",
        columns: [
          {
            key: "user.application.st_bridge",
            label: "Bridge",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.st_bhs",
            label: "BHS",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.st_m_bhs",
            label: "BHS #",
            rotated: true,
            gradient: {
              max: 4,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.st_tuck",
            label: "Tuck",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.st_full",
            label: "Full",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
        ],
      },
      {
        key: "running_tumbling",
        label: "Running Tumbling",
        hover: "user.application.rt_comments",
        columns: [
          {
            key: "user.application.rt_cartwheel",
            label: "CW",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.rt_ro_bhs",
            label: "RO BHS",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.rt_ro_multiple_bhs",
            label: "RO BHS BHS",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },

          {
            key: "user.application.rt_cartwheel_bt",
            label: "CW BT",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.rt_ro_bhs_bt",
            label: "RO BHS BT",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.rt_ro_bhs_l",
            label: "RO BHS L",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.rt_ro_bhs_l_so",
            label: "RO BHS L SO",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.rt_ro_bhs_full",
            label: "RO BHS F",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
        ],
      },
      {
        key: "stunting",
        label: "Stunting",
        hover: "user.application.stunt_comments",
        columns: [
          {
            key: "user.application.stunt_hand_reach",
            label: "Hand Reach",
            rotated: true,
          },
          {
            key: "user.application.stunt_height",
            label: "Height",
            rotated: true,
          },
          {
            key: ({ user }) =>
              ({
                none: "0",
                beginner: "1",
                intermediate: "2",
                advanced: "3",
              }[user.application && user.application.stunt_overall_experience]),
            label: "Experience",
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
            rotated: true,
          },
          {
            key: ({ user }) =>
              ({
                none: "",
                backspot: "BS",
                flier: "F",
                main_base: "MB",
                secondary_base: "SB",
              }[user.application && user.application.stunt_best_position]),
            label: "Best Position",
            rotated: true,
          },
          {
            key: ({ user }) =>
              ({
                none: "",
                backspot: "BS",
                flier: "F",
                main_base: "MB",
                secondary_base: "SB",
              }[user.application && user.application.stunt_favorite_position]),
            label: "Fave Position",
            rotated: true,
          },
        ],
      },
      {
        key: "fliers",
        label: "Flyers",
        hover: "user.application.stunt_flier_comments",
        columns: [
          {
            key: "user.application.stunt_flier_heel_stretch",
            label: "Heel Stretch",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.stunt_flier_arabesque",
            label: "Arabesque",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.stunt_flier_scorpion",
            label: "Scorpion",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.stunt_flier_scale",
            label: "Scale",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.application.stunt_flier_bowarrow",
            label: "Bow & Arrow",
            rotated: true,
            gradient: {
              max: 3,
              color: [96, 105, 114],
            },
          },
        ],
      },
      {
        key: "conditioning",
        label: "Conditioning",
        columns: [
          {
            key: "user.assessments.laps",
            rotated: true,
            label: "Laps",
            gradient: {
              min: 0,
              max: 11,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.assessments.mile",
            rotated: true,
            label: "Mile",
            gradient: {
              min: 360,
              max: 700,
              color: [96, 105, 114],
              invert: true,
            },
          },
          {
            key: "user.assessments.push_ups",
            rotated: true,
            label: "Push Ups",
            gradient: {
              max: 75,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.assessments.wall_sit_time",
            rotated: true,
            label: "Wall Sit",
            gradient: {
              max: 180,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.assessments.sit_ups",
            rotated: true,
            label: "Sit Ups",
            gradient: {
              max: 75,
              color: [96, 105, 114],
            },
          },
        ],
      },
      {
        key: "evaluations",
        label: "Evaluations",
        columns: [
          {
            key: "user.evaluations.sections.flexibility.split_left",
            rotated: true,
            label: "Left Split",
            round: true,
          },
          {
            key: "user.evaluations.sections.flexibility.split_middle",
            rotated: true,
            label: "Middle Split",
            round: true,
          },
          {
            key: "user.evaluations.sections.flexibility.split_right",
            rotated: true,
            label: "Right Split",
            round: true,
          },
          {
            key: "user.evaluations.sections.flexibility.bridge",
            rotated: true,
            label: "Bridge",
            round: true,
          },
          {
            key: "user.evaluations.sections[1].mean",
            label: "Jumps",
            rotated: true,
            round: true,
            gradient: {
              max: 20,
              color: [96, 105, 114],
            },
            max: 20,
            percentage: true,
          },
          {
            key: "user.evaluations.sections[2].mean",
            label: "Motions",
            rotated: true,
            round: true,
            gradient: {
              max: 20,
              color: [96, 105, 114],
            },
            max: 20,
            percentage: true,
          },
          {
            key: "user.evaluations.sections[3].mean",
            label: "Cheer",
            rotated: true,
            round: true,
            gradient: {
              max: 20,
              color: [96, 105, 114],
            },
            max: 20,
            percentage: true,
          },
          {
            key: "user.evaluations.sections[4].mean",
            label: "Dance",
            rotated: true,
            round: true,
            gradient: {
              max: 20,
              color: [96, 105, 114],
            },
            max: 20,
            percentage: true,
          },
          {
            key: "user.evaluations.total",
            label: "Total",
            rotated: true,
            round: true,
            gradient: {
              max: 80,
              color: [231, 67, 2],
            },
            max: 80,
            percentage: true,
          },
        ],
      },
      {
        key: "recommendations",
        label: "Recommendations",
        columns: [
          {
            key: "user.recommendations.attendance.mean",
            label: "Attendance",
            hover: "user.recommendations.attendance.summary",
            rotated: true,
            gradient: {
              max: 5,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.recommendations.attitude.mean",
            label: "Attitude",
            hover: "user.recommendations.attitude.summary",
            rotated: true,
            gradient: {
              max: 5,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.recommendations.academia.mean",
            label: "Academia",
            hover: "user.recommendations.academia.summary",
            rotated: true,
            gradient: {
              max: 5,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.recommendations.sociability.mean",
            label: "Sociability",
            hover: "user.recommendations.sociability.summary",
            rotated: true,
            gradient: {
              max: 5,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.recommendations.ambition.mean",
            label: "Ambition",
            hover: "user.recommendations.ambition.summary",
            rotated: true,
            gradient: {
              max: 5,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.recommendations.reliability.mean",
            label: "Reliability",
            hover: "user.recommendations.reliability.summary",
            rotated: true,
            gradient: {
              max: 5,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.recommendations.coachability.mean",
            label: "Coachability",
            hover: "user.recommendations.coachability.summary",
            rotated: true,
            gradient: {
              max: 5,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.recommendations.enthusiasm.mean",
            label: "Enthusiasm",
            hover: "user.recommendations.enthusiasm.summary",
            rotated: true,
            gradient: {
              max: 5,
              color: [96, 105, 114],
            },
          },
          {
            key: "user.recommendations.recommended.no.count",
            label: "'No' Rec",
            hover: "user.recommendations.recommended_no",
            highlight: true,
            rotated: true,
          },
          {
            key: "user.recommendations.disciplinary_procedures.yes.count",
            label: "Discipline",
            hover: "user.recommendations.disciplinary_procedures_explanation",
            highlight: true,
            rotated: true,
          },
          {
            key: "user.recommendations.comments.length",
            label: "Comments",
            hover: "user.recommendations.comments",
            rotated: true,
          },
        ],
      },
    ],
  },
  evaluation: {
    show_percentage: true,
    total_points: 80,
    sections: [
      {
        name: "flexibility",
        title: "Flexibility",
        points: 0,
        fields: [
          {
            label: "Right Split",
            short: "Right",
            key: "split_right",
            values: [0, 1, 2, 3, 4, 5],
          },
          {
            label: "Left Split",
            short: "Left",
            key: "split_left",
            values: [0, 1, 2, 3, 4, 5],
          },
          {
            label: "Middle Split",
            short: "Middle",
            key: "split_middle",
            values: [0, 1, 2, 3, 4, 5],
          },
          {
            label: "Bridge",
            short: "Bridge",
            key: "bridge",
            values: [0, 1, 2, 3, 4, 5],
          },
        ],
      },
      {
        name: "jumps",
        title: "Jumps",
        points: 20,
        fields: [
          {
            label: "Single Toe Touch",
            short: "TT",
            key: "j_single",
            values: [0, 2, 4, 6, 8, 10],
          },
          {
            label: "Triple Jump Combo",
            short: "Triple",
            key: "j_combo",
            values: [0, 2, 4, 6, 8, 10],
          },
        ],
      },
      {
        name: "motion",
        title: "Motion Sequence",
        points: 20,
        fields: [
          {
            label: "Memory & Rhythm",
            short: "Memory",
            key: "m_memory",
            values: [0, 2, 4, 6, 8, 10],
          },
          {
            label: "Motion Placement & Sharpness",
            short: "Motion",
            key: "m_motion",
            values: [0, 2, 4, 6, 8, 10],
          },
        ],
      },
      {
        name: "cheer",
        title: "Cheer",
        points: 20,
        fields: [
          {
            label: "Memory & Rhythm",
            short: "Memory",
            key: "c_memory",
            values: [0, 1, 2, 3, 4, 5],
          },
          {
            label: "Motion Placement & Sharpness",
            short: "Motion",
            key: "c_motion",
            values: [0, 1, 2, 3, 4, 5],
          },
          {
            label: "Spirit & Presentation",
            short: "Spirit",
            key: "c_spirit",
            values: [0, 2, 4, 6, 8, 10],
          },
        ],
      },
      {
        name: "dance",
        title: "Dance",
        points: 20,
        fields: [
          {
            label: "Memory & Rhythm",
            short: "Memory",
            key: "d_memory",
            values: [0, 1, 2, 3, 4, 5],
          },
          {
            label: "Dance Technique",
            short: "Tech",
            key: "d_technique",
            values: [0, 2, 4, 6, 8, 10],
          },
          {
            label: "Showmanship & Presentation",
            short: "Show",
            key: "d_show",
            values: [0, 1, 2, 3, 4, 5],
          },
        ],
      },
    ],
  },
  lists: {
    teachersBySchool: require("./teachersBySchool2023.json"),
  },
  sections: [
    {
      title: "Basic Information",
      icon: "Person",
      print: false,
      fields: [
        {
          label: "Name",
          key: "name",
          type: "textfield",
          required: true,
          validate: "fullName",
          assessments: [
            {
              key: "team",
              label: "Team",
              type: "select",
              values: ["", "-", "Varsity Elite", "Varsity Spirit"],
            },
            {
              key: "laps",
              label: "Laps",
              type: "textfield",
              subtype: "number",
            },
            {
              key: "mile",
              label: "Mile",
              type: "textfield",
            },
            {
              key: "push_ups",
              label: "Push Ups",
              type: "textfield",
              subtype: "number",
            },
            {
              key: "wall_sit_time",
              label: "Wall Sit",
              type: "textfield",
            },
            {
              key: "sit_ups",
              label: "Sit Ups",
              type: "textfield",
              subtype: "number",
            },
          ],
        },
        {
          label: "Email",
          key: "email",
          type: "textfield",
          subtype: "email",
          validate: [
            "email",
            {
              type: "disallowEmailDomains",
              domains: ["alpinesd.org"],
              message: "Please don't use your school email address.",
            },
            {
              type: "disallowEmailDomains",
              domains: ["hotmail.com"],
              message:
                "Hotmail will not deliver our emails. Please use a different email address.",
            },
          ],
          required: true,
        },
        {
          label: "Phone",
          key: "phone",
          type: "textfield",
          subtype: "tel",
          required: true,
        },
        {
          label: "Gender",
          key: "gender",
          type: "select",
          values: ["Male", "Female"],
          required: true,
        },
        {
          label: "Date of Birth",
          key: "birthday",
          type: "date",
          required: true,
          initialFocusedDate: "1/1/2005",
        },
        {
          label: "Parent/Legal Guardian Name",
          key: "guardian_name",
          type: "textfield",
          required: true,
          validate: "fullName",
        },
        {
          label: "Parent/Legal Guardian Email",
          key: "guardian_email",
          type: "textfield",
          subtype: "email",
          validate: [
            "email",
            {
              type: "disallowEmailDomains",
              domains: ["alpinesd.org"],
              message: "Please don't use your school email address.",
            },
            {
              type: "disallowEmailDomains",
              domains: ["hotmail.com"],
              message:
                "Hotmail will not deliver our emails. Please use a different email address.",
            },
          ],
          required: true,
        },
        {
          label: "Parent/Legal Guardian Phone",
          key: "guardian_phone",
          type: "textfield",
          subtype: "tel",
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <p>
              Please list all dates, if any, you will be absent during the
              spring and summer schedule as outlined in{" "}
              <a
                target="_blank"
                href="https://skyridgecheer.com/bylaws"
                rel="noreferrer"
              >
                Article Five, Section Six of the Bylaws
              </a>
              . Please remember your full attendance is critical to the success
              of this program. (Enter "None" if there aren't any.)
            </p>
          ),
        },
        {
          label: "Known Spring and Summer Absences",
          key: "dates_absent",
          type: "textfield",
          multiline: true,
          rows: 4,
          required: true,
          variant: "outlined",
        },
      ],
    },
    {
      title: "School Information",
      icon: "School",
      print: false,
      fields: [
        {
          label: "Grade for CURRENT School Year",
          key: "grade_current",
          type: "select",
          values: ["8th", "9th", "10th", "11th"],
          required: true,
        },
        {
          label: "Grade for 2023-2024 School Year",
          key: "grade",
          type: "select",
          values: ["9th", "10th", "11th", "12th"],
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div>
              <br />
              <span style={{ color: "red" }}>
                <b>WARNING!</b>
              </span>{" "}
              Please check that you entered the correct grades above.
            </div>
          ),
          hidden: (userData) => {
            let { grade_current, grade } = userData;

            if (!grade_current || !grade) return true;
            if (grade_current === "8th" && grade === "9th") return true;
            if (grade_current === "9th" && grade === "10th") return true;
            if (grade_current === "10th" && grade === "11th") return true;
            if (grade_current === "11th" && grade === "12th") return true;

            return false;
          },
        },
        {
          label: "Current School",
          key: "school",
          type: "select",
          other: "Current School Name",
          values: [
            "Skyridge High School",
            "Lehi Junior High",
            "Mountain Ridge Junior High",
            "Viewpoint Middle School",
          ],
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div>
              <br />
              <p>
                To remain compliant with UHSAA eligibility requirements, please
                indicate whether you have attended a <b>high school</b> besides
                Skyridge High School for any reason.
              </p>
            </div>
          ),
        },
        {
          label: "Attended Other High School?",
          key: "other_high_school",
          type: "select",
          other: "Name of School and Reason",
          other_option: "Yes",
          values: ["No"],
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div>
              <br />
              <h3>Teachers</h3>
              <p>
                Please select your teacher for each period. If your teacher is
                not listed, choose "Other" and enter the teacher's email address
                into the field below. We will be contacting all teachers for
                recommendations.
              </p>
            </div>
          ),
        },
        {
          type: "select",
          key: "teacher_a1",
          label: "A1 Teacher",
          other: "A1 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
        {
          type: "select",
          key: "teacher_a2",
          label: "A2 Teacher",
          other: "A2 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
        {
          type: "select",
          key: "teacher_a3",
          label: "A3 Teacher",
          other: "A3 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
        {
          type: "select",
          key: "teacher_a4",
          label: "A4 Teacher",
          other: "A4 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
        {
          type: "select",
          key: "teacher_b5",
          label: "B5 Teacher",
          other: "B5 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
        {
          type: "select",
          key: "teacher_b6",
          label: "B6 Teacher",
          other: "B6 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
        {
          type: "select",
          key: "teacher_b7",
          label: "B7 Teacher",
          other: "B7 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
        {
          type: "select",
          key: "teacher_b8",
          label: "B8 Teacher",
          other: "B8 Teacher Email Address",
          other_option: "Other",
          other_subtype: "email",
          values: {
            additionalValues: ["No class", "Seminary"],
            fromListOfLists: "teachersBySchool",
            lookupKey: "userData.school",
          },
          validate: "emailOrNoClass",
          required: true,
        },
      ],
    },
    {
      title: "Grades & Attendance",
      icon: "Assignment",
      print: false,
      fields: [
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div>
              <br />
              <h3>2022-2023 Grades</h3>
              <p>
                Please upload a screenshot or picture of your grades so far this
                year on Skyward (or equivalent system if your school does not
                use Skyward.) Upload multiple if needed to show all classes and
                all terms. If your screenshots are incomplete, the application
                will be returned to you to finish.
              </p>
            </div>
          ),
        },
        {
          type: "upload",
          label: "Grades",
          key: "grades",
          required: true,
          maxFiles: 4,
          accept:
            "image/*, .heic, .hevc, .heif, .pdf, .png, .gif, .jpg, .jpeg, .doc, .docx, image/png, image/jpg, image/jpeg, image/gif, application/msword, application/pdf",
          showImage: false,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div>
              <br />
              <h3>2022-2023 GPA</h3>
              <p>
                Please upload a screenshot of the GPA section of Skyward for the
                current school year. This can be found in the "Gradebook"
                section, under the "GPA" tab. If your school does not use
                Skyward, please find the equivalent screen, or calculate your
                GPA by hand and upload a picture.
              </p>
            </div>
          ),
        },
        {
          type: "upload",
          label: "GPA",
          key: "gpa",
          required: true,
          maxFiles: 3,
          accept:
            "image/*, .heic, .hevc, .heif, .pdf, .png, .gif, .jpg, .jpeg, .doc, .docx, image/png, image/jpg, image/jpeg, image/gif, application/msword, application/pdf",
          showImage: false,
          assessments: [
            {
              key: "gpa",
              label: "GPA",
              type: "textfield",
              subtype: "number",
            },
          ],
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div>
              <br />
              <h3>2022-2023 Attendance</h3>
              <p>
                Please upload a screenshot or picture of your attendance (the "
                <b>2022-2023 Totals</b>" tab) from Skyward (or equivalent system
                if your school does not use Skyward.) Include multiple
                screenshots, if necessary, to show all classes and all terms. If
                your screenshots are incomplete, your application will be
                returned to you to finish.
              </p>
            </div>
          ),
        },
        {
          type: "upload",
          label: "Attendance",
          key: "attendance",
          required: true,
          maxFiles: 4,
          accept:
            "image/*, .heic, .hevc, .heif, .pdf, .png, .gif, .jpg, .jpeg, .doc, .docx, image/png, image/jpg, image/jpeg, image/gif, application/msword, application/pdf",
          showImage: false,
        },
      ],
    },
    {
      title: "Experience",
      icon: "Poll",
      print: false,
      fields: [
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <p>
              Please briefly describe any past cheerleading, tumbling, or dance
              experience you have.
            </p>
          ),
        },
        {
          label: "Experience",
          key: "experience",
          type: "textfield",
          multiline: true,
          rows: 6,
          required: true,
          variant: "outlined",
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <p>
              <br />
              Please enter the contact information for two previous cheer
              coaches. If you don't have previous cheer coaches, enter the
              information for past coaches for a different sport, music
              teachers, etc. (We will be contacting these people for
              recommendations. Make sure the email addresses are correct.)
            </p>
          ),
        },
        {
          label: "First Coach Name",
          key: "prev_coach_name",
          type: "textfield",
          required: true,
          validate: "fullName",
        },
        {
          label: "First Coach Email",
          key: "prev_coach_email",
          type: "textfield",
          subtype: "email",
          validate: "email",
          required: true,
        },
        {
          label: "Second Coach Name",
          key: "prev2_coach_name",
          type: "textfield",
          required: true,
          validate: "fullName",
        },
        {
          label: "Second Coach Email",
          key: "prev2_coach_email",
          type: "textfield",
          subtype: "email",
          validate: "email",
          required: true,
        },
      ],
    },
    {
      title: "Headshot",
      icon: "Camera",
      print: false,
      wrapperStyle: { margin: "70px 0 0", padding: "0 0 20px 0" },
      fields: [
        {
          type: "upload",
          label: "Headshot",
          key: "headshot",
          required: true,
          accept: "image/*",
          showImage: true,
        },
      ],
    },
    {
      title: "Standing Tumbling Experience",
      icon: "Accessibility",
      print: false,
      fields: [
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <p>
                Please rate yourself on the following standing tumbling skills{" "}
                <b>ON A NON-SPRING FLOOR</b>:
              </p>
              <ul>
                <li>
                  <b>0</b> - No experience
                </li>
                <li>
                  <b>1</b> - Can execute, but not consistently
                </li>
                <li>
                  <b>2</b> - Can consistently hit but not with good form and
                  technique
                </li>
                <li>
                  <b>3</b> - Can consistenly hit with good form and technique
                </li>
              </ul>
              <br />
              <Typography color="primary">
                <i>
                  Please be accurate and honest in all self-evaluations. Coaches
                  will observe and evaluate all tumbling and stunting skills at
                  tryouts. Use the text box at the bottom to add any additional
                  clarifying information.
                </i>
              </Typography>
            </div>
          ),
        },
        {
          label: "Bridge or back-walkover",
          key: "st_bridge",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Standing back-handspring",
          key: "st_bhs",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Standing back-tuck",
          key: "st_tuck",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Standing full (back-tuck with full twist)",
          key: "st_full",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: (
            <>
              Multiple standing back handsprings with no round-off or step
              before. <i>Enter how many.</i>
            </>
          ),
          key: "st_m_bhs",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
            ["4+", "4+"],
          ],
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <hr />
              <p>
                Please expound on your standing tumbling experience. Is there
                anything you would like the coaches to know?
              </p>
            </div>
          ),
        },
        {
          label: "Standing tumbling comments",
          key: "st_comments",
          type: "textfield",
          multiline: true,
          rows: 4,
          required: true,
          variant: "outlined",
        },
      ],
    },
    {
      title: "Running Tumbling Experience",
      icon: "DirectionsWalk",
      print: false,
      fields: [
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <p>
                Please rate yourself on how well you can perform the following
                running tumbling skills <b>ON A NON-SPRING FLOOR</b>:
              </p>
              <p>
                <i>*BHS means back-handspring</i>
              </p>
              <ul>
                <li>
                  <b>0</b> - No experience
                </li>
                <li>
                  <b>1</b> - Can execute, but not consistently
                </li>
                <li>
                  <b>2</b> - Can consistently hit but not with good form and
                  technique
                </li>
                <li>
                  <b>3</b> - Can consistenly hit with good form and technique
                </li>
              </ul>
              <Typography color="primary">
                <i>
                  Please be accurate and honest in all self-evaluations. Coaches
                  will observe and evaluate all tumbling and stunting skills at
                  tryouts. Use the text box at the bottom to add any additional
                  clarifying information.
                </i>
              </Typography>
            </div>
          ),
        },
        {
          label: "Cartwheel or round-off",
          key: "rt_cartwheel",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Round-off BHS",
          key: "rt_ro_bhs",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Round-off multiple BHS",
          key: "rt_ro_multiple_bhs",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Connected cartwheel back-tuck",
          key: "rt_cartwheel_bt",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Round-off BHS back-tuck",
          key: "rt_ro_bhs_bt",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Round-off BHS layout",
          key: "rt_ro_bhs_l",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Round-off BHS layout step-out",
          key: "rt_ro_bhs_l_so",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          label: "Round-off BHS full twist",
          key: "rt_ro_bhs_full",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <hr />
              <p>
                Please expound on your running tumbling experience. Is there
                anything you would like the coaches to know?
              </p>
            </div>
          ),
        },
        {
          label: "Running tumbling comments",
          key: "rt_comments",
          type: "textfield",
          multiline: true,
          rows: 4,
          required: true,
          variant: "outlined",
        },
      ],
    },
    {
      title: "Stunting Experience",
      icon: "Sports",
      print: false,
      fields: [
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <p>
                Measure your hand reach in feet and inches. Stand with feet
                together, flat on the floor, and extend arms above head. Measure
                from floor to base of wrist. This measurement, and your height,
                helps us gauge stunt group placement.
              </p>

              <a
                href="https://skyridgecheer.com/handreach.jpg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="hand reach demonstration"
                  style={{ width: "100%" }}
                  src="https://skyridgecheer.com/handreach.jpg"
                />
              </a>
            </div>
          ),
        },
        {
          label: "Hand Reach (example: 6' 3\")",
          shortLabel: "Hand Reach",
          key: "stunt_hand_reach",
          type: "textfield",
          required: true,
          validate: "feetAndInches",
        },
        {
          label: "Height (example: 5' 6\")",
          shortLabel: "Height",
          key: "stunt_height",
          type: "textfield",
          required: true,
          validate: "feetAndInches",
        },

        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <br />
              <Typography color="primary">
                <i>
                  Please be accurate and honest in all self-evaluations. Coaches
                  will observe and evaluate all tumbling and stunting skills at
                  tryouts. Use the text box at the bottom to add any additional
                  clarifying information.
                </i>
              </Typography>
            </div>
          ),
        },
        {
          label:
            "What group stunting positions have you performed? (Check all that apply)",
          shortLabel: "Stunt Positions",
          key: "stunt_positions",
          type: "choosemany",
          options: [
            [
              "none",
              "I have never stunted",
              {
                disable: ["backspot", "flier", "main_base", "secondary_base"],
              },
            ],
            ["backspot", "Backspot", { disable: ["none"] }],
            ["flier", "Flyer", { disable: ["none"] }],
            ["main_base", "Main Base", { disable: ["none"] }],
            ["secondary_base", "Secondary Base", { disable: ["none"] }],
          ],
          required: true,
        },
        {
          label: "What stunting position are you best at?",
          shortLabel: "Best Position",
          key: "stunt_best_position",
          type: "chooseone",
          options: [
            ["none", "Not applicable"],
            ["backspot", "Backspot"],
            ["flier", "Flyer"],
            ["main_base", "Main Base"],
            ["secondary_base", "Secondary Base"],
          ],
          required: true,
        },
        {
          label: "What stunting position is your favorite to perform?",
          shortLabel: "Favorite Position",
          key: "stunt_favorite_position",
          type: "chooseone",
          options: [
            ["none", "Not applicable"],
            ["backspot", "Backspot"],
            ["flier", "Flyer"],
            ["main_base", "Main Base"],
            ["secondary_base", "Secondary Base"],
          ],
          required: true,
        },
        {
          label: "How would you rate your most advanced stunting experience?",
          shortLabel: "Stunt Experience",
          key: "stunt_overall_experience",
          type: "chooseone",
          options: [
            ["none", "I have never stunted"],
            [
              "beginner",
              <p>
                <b>Beginner:</b> Two-footed prep-level and two-footed extended
                level skills with no twisting or transitions
              </p>,
            ],
            [
              "intermediate",
              <p>
                <b>Intermediate:</b> Two-footed extended skills with twisting or
                transitions and basic single-footed extended skills without
                twisting or transitions
              </p>,
            ],
            [
              "advanced",
              <p>
                <b>Advanced:</b> Single-footed extended skills with twisting,
                switch-up transitions, or full around skills
              </p>,
            ],
          ],
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <hr />
              <p>
                Please expound on your stunting experience. Is there anything
                you would like the coaches to know?
              </p>
            </div>
          ),
        },
        {
          label: "Stunting comments",
          key: "stunt_comments",
          type: "textfield",
          multiline: true,
          rows: 4,
          required: true,
          variant: "outlined",
        },
        {
          type: "text",
          hideOnSummary: true,
          hidden: (userData) =>
            userData["stunt_best_position"] !== "flier" &&
            userData["stunt_favorite_position"] !== "flier",
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <br />
              <h3>Additional Flyer Information</h3>
              <p>
                Please rate yourself on how well you can perform the following
                flyer positions on your right leg (click on each position for an
                example.)
              </p>
              <ul>
                <li>
                  <b>0</b> - No experience
                </li>
                <li>
                  <b>1</b> - Can execute, but not consistently
                </li>
                <li>
                  <b>2</b> - Can consistently hit but not with good form and
                  technique
                </li>
                <li>
                  <b>3</b> - Can consistenly hit with good form and technique
                </li>
              </ul>
            </div>
          ),
        },
        {
          label: (
            <a
              href="https://skyridgecheer.com/flier_heelstretch.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              Heel stretch
            </a>
          ),
          labelShort: "Heel stretch",
          key: "stunt_flier_heel_stretch",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          hidden: (userData) =>
            userData["stunt_best_position"] !== "flier" &&
            userData["stunt_favorite_position"] !== "flier",
          required: true,
        },
        {
          label: (
            <a
              href="https://skyridgecheer.com/flier_arabesque.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              Arabesque
            </a>
          ),
          labelShort: "Arabesque",
          key: "stunt_flier_arabesque",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          hidden: (userData) =>
            userData["stunt_best_position"] !== "flier" &&
            userData["stunt_favorite_position"] !== "flier",
          required: true,
        },
        {
          label: (
            <a
              href="https://skyridgecheer.com/flier_scorpion.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              Scorpion
            </a>
          ),
          labelShort: "Scorpion",
          key: "stunt_flier_scorpion",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          hidden: (userData) =>
            userData["stunt_best_position"] !== "flier" &&
            userData["stunt_favorite_position"] !== "flier",
          required: true,
        },
        {
          label: (
            <a
              href="https://skyridgecheer.com/flier_scale.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              Scale
            </a>
          ),
          labelShort: "Scale",
          key: "stunt_flier_scale",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          hidden: (userData) =>
            userData["stunt_best_position"] !== "flier" &&
            userData["stunt_favorite_position"] !== "flier",
          required: true,
        },
        {
          label: (
            <a
              href="https://skyridgecheer.com/flier_bowandarrow.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bow &amp; arrow
            </a>
          ),
          labelShort: "Bow & arrow",
          key: "stunt_flier_bowarrow",
          type: "chooseone",
          inline: true,
          options: [
            ["0", "0"],
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
          ],
          hidden: (userData) =>
            userData["stunt_best_position"] !== "flier" &&
            userData["stunt_favorite_position"] !== "flier",
          required: true,
        },
        {
          type: "text",
          hideOnSummary: true,
          hidden: (userData) =>
            userData["stunt_best_position"] !== "flier" &&
            userData["stunt_favorite_position"] !== "flier",
          text: (
            <div style={{ fontSize: "1.2em" }}>
              <p>
                What else would you like the coaches to know about your flying
                experience?
              </p>
            </div>
          ),
        },
        {
          label: "Flyer position comments",
          key: "stunt_flier_comments",
          type: "textfield",
          multiline: true,
          rows: 4,
          required: true,
          hidden: (userData) =>
            userData["stunt_best_position"] !== "flier" &&
            userData["stunt_favorite_position"] !== "flier",
          variant: "outlined",
        },
      ],
    },
    {
      title: "Essays",
      icon: "Keyboard",
      print: false,
      fields: [
        {
          type: "text",
          hideOnSummary: true,
          text: (
            <p>
              Please write two essays on the following topics. They don't have
              to be long, but they should be thoughtful.
            </p>
          ),
        },
        {
          type: "text",
          text: (
            <div style={{ marginTop: "30px" }}>
              <hr />
              <p>
                Do you think talent or hard work wins in the end? Explain, using
                an example from your life.
              </p>
            </div>
          ),
        },
        {
          label: "",
          key: "essay_talent",
          type: "textfield",
          multiline: true,
          rows: 10,
          required: true,
          variant: "outlined",
          assessments: [
            {
              key: "essay_grade",
              label: "Essay Grade",
              type: "textfield",
              subtype: "number",
            },
          ],
        },
        {
          type: "text",
          text: (
            <div style={{ marginTop: "30px" }}>
              <hr />
              <p>
                What do you consider to be the strengths of the Skyridge
                community? What would you change?
              </p>
            </div>
          ),
        },
        {
          label: "",
          key: "essay_skyridge",
          type: "textfield",
          multiline: true,
          rows: 10,
          required: true,
          variant: "outlined",
        },
      ],
    },
    {
      title: "Bylaws Acceptance",
      icon: "Gesture",
      paper: true,
      print: true,
      fields: [
        {
          type: "text",
          printOnly: true,
          text: (
            <>
              <span className="center">
                <h3>
                  Skyridge High School Cheerleading Program
                  <br />
                  2023-2024 School Year
                </h3>
                <br />
                <h1>Bylaws Acceptance Form</h1>
              </span>
              <hr />
            </>
          ),
        },
        {
          type: "text",
          text: (
            <>
              <p className="justify line-height-2x">
                We, the undersigned, fully understand the criteria for obtaining
                and maintaining membership on the 2023-2024 Skyridge High School
                Cheerleading and Stunt Crew teams. We have read and agree to
                uphold all of the Articles and Sections of the 2023-2024
                Skyridge High School Cheer &amp; Stunt Crew Bylaws.
              </p>

              <p>
                <b>
                  <a
                    href="https://skyridgecheer.com/bylaws"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read 2023-2024 Bylaws Here
                  </a>
                </b>
              </p>
            </>
          ),
        },
        {
          type: "signature",
          label: "Guardian Signature",
          key: "bylaws_guardian_sig",
          required: true,
        },
        {
          type: "signature",
          label: "Student Signature",
          key: "bylaws_student_sig",
          required: true,
        },
      ],
    },
    {
      title: "Informed Consent & Acknowledgement Agreement",
      icon: "Gesture",
      print: true,
      paper: true,
      fields: [
        {
          type: "text",
          printOnly: true,
          text: (
            <>
              <span className="center">
                <h3>
                  Skyridge High School Cheerleading Program
                  <br />
                  2023-2024 School Year
                </h3>
                <br />
                <h1>Informed Consent & Acknowledgement Agreement</h1>
              </span>
            </>
          ),
        },
        {
          type: "text",
          text: ({ userData, today }) => (
            <div className="justify">
              <p>
                <b>WARNING:</b> Cheerleading is a vigorous physical activity
                involving motion, rotation, and height in a unique environment,
                and as such carries with it a higher than ordinary risk of
                injury. Be advised that serious catastrophic injury, paralysis,
                or even death could occur, particularly if a participant were to
                land on his/her head, neck, or back.
              </p>
              <p>
                I/We, parents and/or legal guardians of{" "}
                <b>
                  <u>&nbsp; &nbsp;{userData.name}&nbsp; &nbsp;</u>
                </b>
                , who is a student at Skyridge high School, wishes to
                participate in the Skyridge High School Cheerleading Program.
                I/we voluntarily give our consent for such participation by our
                daughter/son.
              </p>

              <p>
                Cheerleading is an activity that may involve airborne inversion
                of the body and therefore possesses increased potential that any
                one of the routines involving our child’s participation could
                lead to serious injury, paralysis or even death. We understand
                that our child is required to be in good physical shape and
                condition and that the activities in which he/she will be asked
                to participate are strenuous and require physical and athletic
                agility. These activities include, but are not necessarily
                limited to, a variety of tumbling maneuvers including
                somersaults, back handsprings, back tucks, and twisting
                rotations; that there will be a variety of mounts, tosses, and
                stunts requiring the coordination of more than one participant
                on the squad; and that these activities will not be confined to
                any one site or venue, but rather will involve a variety of
                sites or venues throughout the year. We represent to you that,
                to the best of our knowledge and belief, our child has no
                physical, medical, or mental disability or other limitation that
                would restrict her ability to fully participate in this
                activity. We agree to notify immediately the appropriate school
                personnel in the event of any change in our child’s health
                status. We also understand that our child will be required to
                travel to locations off campus for the purpose of participating
                in cheerleading activities and the appropriate school personnel
                will provide that transportation to him/her. We consent to such
                school sponsored transportation. We agree to, and by signing
                this agreement, release the coaches, advisors, volunteers and
                staff of Skyridge High School and the Board of Education from
                any claim of negligence by ourselves and our child, and from any
                liability arising from claims for damages for injury to our
                child and any claims for loss of or damage to his/her property
                which may arise out of his/her participation in this
                school-sponsored program for the 2023-2024 year.
              </p>

              <p>
                In witness thereof, I/we have affixed our signatures to this
                agreement today,{" "}
                <b>
                  {(userData.informed_consent_guardian_sig
                    ? new Date(userData.informed_consent_guardian_sig.date)
                    : today
                  ).toLocaleDateString("en-US", {
                    month: "long",
                    timeZone: "America/Denver",
                  })}{" "}
                  {(userData.informed_consent_guardian_sig
                    ? new Date(userData.informed_consent_guardian_sig.date)
                    : today
                  ).toLocaleDateString("en-US", {
                    day: "numeric",
                    timeZone: "America/Denver",
                  })}
                  ,{" "}
                  {(userData.informed_consent_guardian_sig
                    ? new Date(userData.informed_consent_guardian_sig.date)
                    : today
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    timeZone: "America/Denver",
                  })}
                </b>
                .
              </p>
            </div>
          ),
        },
        {
          type: "signature",
          label: "Guardian Signature",
          key: "informed_consent_guardian_sig",
          required: true,
        },
        {
          type: "signature",
          label: "Student Signature",
          key: "informed_consent_student_sig",
          required: true,
        },
      ],
    },
    {
      title: "Tryout Permission Slip",
      icon: "Gesture",
      print: true,
      paper: true,
      fields: [
        {
          type: "text",
          printOnly: true,
          text: (
            <>
              <span className="center">
                <h3>
                  Skyridge High School Cheerleading Program
                  <br />
                  2023-2024 School Year
                </h3>
                <br />
                <h1>Tryout Permission Slip</h1>
              </span>
              <hr />
            </>
          ),
        },
        {
          type: "text",
          text: ({ userData }) => (
            <div className="justify line-height-2x">
              <p>
                <b>
                  <u>&nbsp; &nbsp;{userData.name}&nbsp; &nbsp;</u>
                </b>{" "}
                has my permission to audition for the 2023-2024 Skyridge High
                School Cheerleading and Stunt Crew Teams. I understand he/she
                must abide by the rules and regulations set forth by the cheer
                coaches and administrators of Skyridge High School.
              </p>

              <p>
                I understand all the criteria for tryouts as listed in{" "}
                <a
                  href="https://www.skyridgecheer.com/bylaws#a10"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Article Ten of the Bylaws
                </a>
                . I also understand that my child will be evaluated fairly by
                the cheer coaches and guest judges during tryouts. I understand
                tryout results are final.
              </p>
            </div>
          ),
        },
        {
          type: "signature",
          label: "Guardian Signature",
          key: "tryout_permission_guardian_sig",
          required: true,
        },
        {
          type: "signature",
          label: "Student Signature",
          key: "tryout_permission_student_sig",
          required: true,
        },
      ],
    },
    {
      title: "Health, Safety & Performance Agreement",
      icon: "Gesture",
      print: true,
      paper: true,
      fields: [
        {
          type: "text",
          printOnly: true,
          text: (
            <>
              <span className="center">
                <h3>
                  Skyridge High School Cheerleading Program
                  <br />
                  2023-2024 School Year
                </h3>
                <br />
                <h1>Health, Safety & Performance Agreement</h1>
              </span>
              <hr />
            </>
          ),
        },
        {
          type: "text",
          text: (
            <div className="justify line-height-2x">
              <p>
                Cheerleading is a vigorous physical activity involving motion,
                rotation, and height in a unique environment. Members are
                expected to maintain their health and strength to have the
                ability to tumble, stunt, and maintain mental focus. Please do
                not try out if existing injuries, diseases, or
                physical/mental/emotional limitations prohibit you from vigorous
                exercise, required attendance, or extreme impact due to stunting
                and tumbling. Please do not execute tumbling skills at tryouts
                you are not comfortable repeatedly performing in subsequent
                practices, performances, and events.
              </p>
              <p>
                <b>DECLARATION:</b> I hereby agree that I have read and
                understand the above conditions, and I acknowledge that my child
                has no existing health conditions or limitations.
              </p>
            </div>
          ),
        },
        {
          type: "signature",
          label: "Guardian Signature",
          key: "hsp_guardian_sig",
          required: true,
        },
        {
          type: "signature",
          label: "Student Signature",
          key: "hsp_student_sig",
          required: true,
        },
      ],
    },
    {
      title: "Fee Transparency Form",
      icon: "Gesture",
      print: true,
      paper: true,
      main_signature_field: "sig_fee_guardian",
      fields: [
        {
          type: "text",
          text: (
            <>
              <span style={{ textAlign: "center" }} className="center">
                <h3>FEE TRANSPARENCY FORM</h3>
                <h4>Skyridge High School</h4>
                <p>3000 North Center Street</p>
                <p>
                  John Wallwork, <i>Principal</i>
                </p>
              </span>
              <hr />
              <div>Extra-Curricular Activity: Cheerleading</div>
              <hr />
              <h4>Board Approved Fees:</h4>
              <p className="smaller">
                <i>
                  Payments can be made at{" "}
                  <a
                    href="https://myschoolfees.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    myschoolfees.com
                  </a>
                  .
                </i>
              </p>
            </>
          ),
        },
        {
          type: "fee",
          amount: required_fees.fee_participation,
          name: "Participation Fee",
          help: "Charged at fall school registration",
          key: "fee_participation",
          resetOnChange: ["sig_fee_guardian", "sig_fee_student"],
          required: true,
          mandatory: true,
        },
        {
          type: "fee",
          amount: required_fees.fee_uniforms,
          name: "Required Uniforms, Spirit Wear, and Summer Fee",
          help: "$300 payments due April 12, April 26, and May 10",
          key: "fee_uniforms",
          resetOnChange: ["sig_fee_guardian", "sig_fee_student"],
          required: true,
          mandatory: true,
        },
        {
          type: "fee",
          amount: required_fees.fee_camp,
          name: "Camp Fee",
          help: "Pay by May 24th",
          key: "fee_camp",
          resetOnChange: ["sig_fee_guardian", "sig_fee_student"],
          required: true,
          mandatory: false,
        },
        {
          type: "text",
          printType: "feeTotal",
          total: ({ userData }) => totalFee(userData),
          text: ({ userData }) => (
            <>
              <hr />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.3em",
                    margin: "auto 0",
                    flexGrow: 1,
                  }}
                >
                  TOTAL DUE:
                </div>
                <div
                  className="fee-total"
                  style={{
                    margin: "auto",
                    textAlign: "center",
                    paddingRight: "10px",
                    fontSize: "1.5em",
                  }}
                >
                  <b> ${totalFee(userData)}</b>
                </div>
              </div>
            </>
          ),
        },
        {
          type: "text",
          text: (
            <>
              <hr />
              <h4>Additional Optional Suggested Costs/Expenses:</h4>
            </>
          ),
        },
        {
          type: "fee",
          amount: optional_fees.fee_nationals,
          name: "Competition Trip (up to)",
          help: "Final costs are determined after fall fundraising events and are due January 2024.",
        },
        {
          type: "text",
          text: ({ userData }) => (
            <>
              <hr />
              <div>
                Participant Name: <b>{userData.name}</b>
              </div>
              <br />
              <br />
            </>
          ),
        },
        {
          type: "signature",
          label: "Guardian Signature",
          key: "sig_fee_guardian",
          required: true,
          prereq_fields: Object.keys(required_fees),
          prereq_message: "Please select an option on each fee before signing.",
          capturedFields: ({ userData }) => selectedFees(userData),
        },
        {
          type: "signature",
          label: "Student Signature",
          key: "sig_fee_student",
          required: true,
          prereq_fields: Object.keys(required_fees),
          prereq_message: "Please select an option on each fee before signing.",
          capturedFields: ({ userData }) => selectedFees(userData),
        },
      ],
    },
    {
      title: "Recommendations",
      recommendations: true,
      icon: "ThumbUp",
      print: false,
      hideOnPreview: true,
      fields: [
        {
          type: "recommendations",
          recommendationFields: {
            teacher_a1: "A1 Teacher",
            teacher_a2: "A2 Teacher",
            teacher_a3: "A3 Teacher",
            teacher_a4: "A4 Teacher",
            teacher_b5: "B5 Teacher",
            teacher_b6: "B6 Teacher",
            teacher_b7: "B7 Teacher",
            teacher_b8: "B8 Teacher",
            prev_coach_email: "Previous Coach 1",
            prev2_coach_email: "Previous Coach 2",
          },
        },
      ],
    },
  ],
};

export default definition;
