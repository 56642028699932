import Handlebars from "handlebars/dist/handlebars";
import get from "lodash/get";
import mapValues from "lodash/mapValues";

const defaults = require("./defaults.json");

Handlebars.registerHelper("formatDateTime", function (date) {
  if (date) {
    return new Date(date).toDateString();
  } else {
    return "";
  }
});

export function Definition(json, name) {
  this.f = json;
  this.id = name;
  this.name = name;
}

Definition.prototype.t = function (path, data) {
  let field = get(this.f, path);
  return this.tf(field, data);
};

Definition.prototype.tf = function (field, data) {
  if (!field) {
    return null;
  }

  if (typeof field === "object" && field.subject) {
    return mapValues(field, (value) => {
      const template = Handlebars.compile(value);
      return template({
        definition: this.f,
        ...data,
      });
    });
  }

  if (typeof field === "function") {
    return field({
      definition: this.f,
      ...data,
    });
  }

  if (typeof field === "string") {
    const template = Handlebars.compile(field);
    return template({
      definition: this.f,
      ...data,
    });
  }

  return field;
};

function defWithDefaults(name) {
  const json = require(`./${name}`).default;
  const jsonWithDefaults = { ...defaults, ...json };
  const def = new Definition(jsonWithDefaults, name);
  return def;
}

const definitions = {
  // SkyridgeStudentCouncil2021: defWithDefaults("SkyridgeStudentCouncil2021"),
  // SkyridgeStudentCouncil2022: defWithDefaults("SkyridgeStudentCouncil2022"),
  // SkyridgeStudentCouncil2023: defWithDefaults("SkyridgeStudentCouncil2023"),
  // SkyridgeCheerTryouts2021: defWithDefaults("SkyridgeCheerTryouts2021"),
  // SkyridgeYouthCheer2021: defWithDefaults("SkyridgeYouthCheer2021"),
  // SkyridgeYouthCheer2022: defWithDefaults("SkyridgeYouthCheer2022"),
  SkyridgeYouthCheer2023: defWithDefaults("SkyridgeYouthCheer2023"),
  SkyridgeYouthCheer2024: defWithDefaults("SkyridgeYouthCheer2024"),
  // SkyridgeMiniCheerCamp2021: defWithDefaults("SkyridgeMiniCheerCamp2021"),
  // SkyridgeMiniCheerCamp2022: defWithDefaults("SkyridgeMiniCheerCamp2022"),
  SkyridgeMiniCheerCamp2023: defWithDefaults("SkyridgeMiniCheerCamp2023"),
  //SkyridgeCheer2022: defWithDefaults("SkyridgeCheer2022"),
  SkyridgeCheer2023: defWithDefaults("SkyridgeCheer2023"),
  SkyridgeCheer2024: defWithDefaults("SkyridgeCheer2024"),
};

export default definitions;
