import React from "react";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import useDefinition from "Hooks/useDefinition";
import SignOutButton from "Components/SignOutButton";

export default function NotFound() {
  const navigate = useNavigate();
  const definition = useDefinition();

  const helpHref = definition
    ? `mailto:${definition.f.email_help}?subject=${definition.f.title} Help`
    : `mailto:zach@zwily.com?subject=LetsApply Help`;

  return (
    <div className="flex flex-col items-center justify-center w-full h-[100vh]">
      <AppBar>
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
            style={{ flexGrow: 1 }}
          ></Typography>
        </Toolbar>
      </AppBar>

      <div className="space-y-6 ">
        <div className="mb-8 text-center">
          Whoops, that page wasn't found.
          <br />
          Your options are:
        </div>

        <Button
          className="w-full"
          variant="contained"
          color="grey"
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>

        <Button
          className="w-full"
          color="secondary"
          variant="contained"
          href={helpHref}
        >
          Get Help
        </Button>

        <SignOutButton className="w-full" />
      </div>
    </div>
  );
}
